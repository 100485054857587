
  import { Options, Vue } from 'vue-class-component';
  import { nextTick } from 'vue';
  import axios from "axios";
  import {RestService} from "../service/rest.service";
  import PaginationComponent from '@/components/pagination/PaginationComponent.vue';

  @Options({
    components: {
      PaginationComponent
    }
  })
  export default class AchievementView extends Vue {

    restService = new RestService('achievements')
    searchForm: any = {}
    items: any = []
    totalPages = 0

    mounted() {
      this.gets()
    }

    gets(queryString?: any) {
      this.restService.gets(queryString).then((data: any) => {
        this.totalPages = data.totalPages
        this.items = data
      })
    }

    changePage(page: number) {
      this.searchForm.page = page
      this.gets(this.searchForm)
    }
  }
