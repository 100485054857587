import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ResourceView from '../views/ResourceView.vue'
import ResourceDetailView from '../views/ResourceDetailView.vue'
import FundView from '../views/FundView.vue'
import FundDetailView from '../views/FundDetailView.vue'
import ListView from '../views/ListView.vue'
import NewsView from '../views/NewsView.vue'
import NewsDetailView from '../views/NewsDetailView.vue'
import EventView from '../views/EventView.vue'
import EventDetailView from '../views/EventDetailView.vue'
import AchievementView from '../views/AchievementView.vue'
import AchievementDetailView from '../views/AchievementDetailView.vue'
import AboutView from '../views/AboutView.vue'
import SitemapView from '../views/SitemapView.vue'
import AIoTAcceleratorView from '../views/AIoTAcceleratorView.vue'
import StartupView from '../views/StartupView.vue'
import AppointmentView from '../views/AppointmentView.vue'
import ResultView from '../views/ResultView.vue'
import QAView from '../views/QAView.vue'
import QADetailView from '../views/QADetailView.vue'
import Error404View from '../views/Error404View.vue'
import Appointment2View from "@/views/Appointment2View.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/resources',
    name: 'resources',
    component: ResourceView
  },
  {
    path: '/resources/:id',
    name: 'resources-detail',
    component: ResourceDetailView
  },
  {
    path: '/funds',
    name: 'funds',
    component: FundView
  },
  {
    path: '/funds/:id',
    name: 'funds-detail',
    component: FundDetailView
  },
  {
    path: '/list',
    name: 'list',
    component: ListView
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView
  },
  {
    path: '/news/:id',
    name: 'news-detail',
    component: NewsDetailView
  },
  {
    path: '/events',
    name: 'events',
    component: EventView
  },
  {
    path: '/events/:id',
    name: 'events-detail',
    component: EventDetailView
  },
  {
    path: '/achievements',
    name: 'achievements',
    component: AchievementView
  },
  {
    path: '/achievements/:id',
    name: 'achievements-detail',
    component: AchievementDetailView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/sitemap',
    name: 'sitemap',
    component: SitemapView
  },
  {
    path: '/aiot_accelerator',
    name: 'aiot_accelerator',
    component: AIoTAcceleratorView
  },
  {
    path: '/startup',
    name: 'startup-search',
    component: StartupView
  },
  {
    path: '/appointment',
    name: 'appointment',
    component: AppointmentView
  },
  {
    path: '/appointment2',
    name: 'appointment2',
    component: Appointment2View
  },
  {
    path: '/result',
    name: 'result',
    component: ResultView
  },
  {
    path: '/qa',
    name: 'qa',
    component: QAView
  },
  {
    path: '/qa/:id',
    name: 'qa-detail',
    component: QADetailView
  },
  {
    path: '/404',
    name: '404',
    component: Error404View
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      if (savedPosition) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            return resolve(savedPosition)
          }, 1000)
        })
      } else {
        return {top: 0}
      }
    } else {
      return {top: 0}
    }
  }
})

router.beforeEach((to, from, next) => {
  document.body.className = ''
  document.title = 'HYS新創竹夢資源網'
  next()
})

export default router
