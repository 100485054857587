<template>
  <main id="main" v-title="'網站導覽'">
    <section class="sec-main sitemapP-main">
      <div class="container-lg">
        <div class="col-sm-10 mx-auto">
          <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link to="/" title="首頁" class="foo: false">首頁</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">關於我們 - 網站導覽</li>
            </ol>
          </nav>
        </div>
        <div class="row head-row">
          <div class="col-12 col-sm-10 mx-auto">
            <div class="appointment-board pt-0">
              <div class="appointment-board-wrapper">
                <h1 class="h2 text-primary mb-4">網站導覽</h1>
                <div class="sitemap-list">
                  <ul style="list-style-type: decimal;">
                    <li class="h3">關於我們</li>
                    <ul class="ps-0 mt-1" style="list-style-type: none;">
                      <li class="h4"><router-link to="/about" title="認識平台" class="foo: false">1.1 認識平台</router-link></li>
                      <li class="h4"><router-link to="/sitemap" title="網站導覽" class="foo: false">1.2 網站導覽</router-link></li>
                    </ul>
                    <li class="h3 mt-5">資源搜尋</li>
                    <ul class="ps-0 mt-1" style="list-style-type: none;">
                      <li class="h4"><router-link to="/resources" title="政府資源總覽" class="foo: false">2.1 政府資源總覽</router-link></li>
                      <li class="h4"><router-link to="/funds" title="資金快搜" class="foo: false">2.2 資金快搜</router-link></li>
                      <li class="h4"><router-link to="/startup" title="青創基地" class="foo: false">2.3 青創基地</router-link></li>
                      <li class="h4"><router-link to="/aiot_accelerator" title="AIoT 加速器" class="foo: false">2.4 AIoT 加速器</router-link></li>
                      <!-- <ul class="mt-1" style="list-style-type: none;">
                        <li class="h4">2.4.1 計畫介紹</li>
                        <li class="h4">2.4.2 企業導師</li>
                        <li class="h4">2.4.3 輔導團隊</li>
                        <li class="h4">2.4.4 近期活動</li>
                      </ul> -->
                    </ul>
                    <li class="h3 mt-5">活動消息</li>
                    <ul class="ps-0 mt-1" style="list-style-type: none;">
                      <li class="h4"><router-link to="/news" title="創業新訊" class="foo: false">3.1 創業新訊</router-link></li>
                      <li class="h4"><router-link to="/events" title="近期活動" class="foo: false">3.2 近期活動</router-link></li>
                      <li class="h4"><router-link to="/achievements" title="亮點成果" class="foo: false">3.3 亮點成果</router-link></li>
                    </ul>
                    <li class="h3 mt-5">創業工具</li>
                    <ul class="ps-0 mt-1" style="list-style-type: none;">
                      <li class="h4"><a href="https://exam.taiwanjobs.gov.tw/JobExam/" title="創業診斷" class="foo: false" target="_blank">4.1 創業診斷</a></li>
                      <li class="h4"><router-link to="/qa" title="創業Q&A" class="foo: false">4.2 創業Q&A</router-link></li>
                      <li class="h4"><router-link to="/appointment" title="創業諮詢" class="foo: false">4.3 創業諮詢</router-link></li>
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script setup lang="ts">
</script>