import axios from 'axios';

export class RestService<T> {

  contextPath = '/rest'
  uri: string

  constructor(type: string) {
    this.uri = this.contextPath + '/v1/' + type
  }

  async create(form: any): Promise<T> {
    const response = await axios.post(this.uri, form)
    return this.toPromise(response.data)
  }

  async gets(queryString?: any): Promise<T> {
    return this.getsBy('', queryString)
  }

  async getsBy(path?: string, queryString?: any): Promise<T> {
    let link = this.uri + path
    if (queryString) {
      link += '?' + this.toQueryString(queryString)
    }

    const response = await axios.get(link)
    return this.toPromise(response.data)
  }

  private toQueryString(queryString?: any) {
    const json: any = Object.fromEntries(
      Object.entries(queryString).filter(([key, value]) => value !== null)
    )

    return new URLSearchParams(json).toString()
  }

  private toPromise(data: T): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      resolve(data);
    })
  }

  async get(id: string): Promise<T> {
    const response = await axios.get(this.uri + '/' + id)
    return this.toPromise(response.data)
  }

  async update(id: string, form: any): Promise<T> {
    const response = await axios.put(this.uri + '/' + id, form)
    return this.toPromise(response.data)
  }

  async delete(id: string): Promise<T> {
    const response = await axios.delete(this.uri + '/' + id)
    return this.toPromise(response.data)
  }

  save(id: string, form: any): Promise<T> {
    if (id) {
      return this.update(id, form)
    } else {
      return this.create(form)
    }
  }

  async recaptcha(token: string) : Promise<T> {
    const response = await axios.post(this.contextPath + '/recaptcha', 'token=' + token)
    return this.toPromise(response.data)
  }
}