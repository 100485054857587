
  import { Options, Vue } from 'vue-class-component';
  import PaginationComponent from '@/components/pagination/PaginationComponent.vue';
  import { RestService } from "../service/rest.service";
  import { Emit, Prop, Ref, Watch } from 'vue-property-decorator'

  export default class ListView extends Vue {

    newsService = new RestService('news')
    eventsService = new RestService('events')
    achievemenstService = new RestService('achievements')

    newsItems: any = []
    eventItems: any = []
    achievementItems: any = []


    mounted() {
      this.getNews()
      this.getEvents()
      this.getAchievements()
    }

    getNews() {
      this.newsService.gets({size: 3}).then((data: any) => {
        this.newsItems = data.content
      })
    }

    getEvents() {
      this.eventsService.gets({size: 3}).then((data: any) => {
        this.eventItems = data.content
      })
    }

    getAchievements() {
      this.achievemenstService.gets({size: 3}).then((data: any) => {
        this.achievementItems = data.content
      })
    }

  }
