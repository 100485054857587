
  import { Options, Vue } from 'vue-class-component';
  import { useRoute } from 'vue-router'
  import { Watch } from 'vue-property-decorator'
  import {RestService} from "../service/rest.service";
  import PaginationComponent from '@/components/pagination/PaginationComponent.vue';

  @Options({
    components: {
      PaginationComponent
    }
  })
  export default class ResultView extends Vue {

    route = useRoute()

    restService = new RestService('search')
    keyword = this.route.query.keyword
    page: any = {}

    nameMap = {
      resources: '政府資源總覽',
      news: '創業新訊',
      funds: '資金快搜',
      events: '近期活動',
      achievements: '亮點成果'
    }

    mounted() {
      this.gets({keyword: this.keyword})

      this.$nextTick(() => {
        setTimeout(() => {
          let mainElem = document.getElementsByClassName('sec-main');
          let aElems = mainElem[0].getElementsByTagName('a');
          for(let i=0; aElems.length > i; i++) {
            let title = aElems[i].getAttribute('title');
            if (title == null) {
              aElems[i].setAttribute('title', aElems[i].text);
            }
          }

          // 議直接於超連結上加註「另開新視窗」。
          let htmlElem = document.getElementsByClassName('editor-html');
          for(let i=0; htmlElem.length > i; i++) {
            let aHtmlElem = htmlElem[i].getElementsByTagName('a');
            for(let i=0; aHtmlElem.length > i; i++) {
              let title = aHtmlElem[i].getAttribute('title');
              aHtmlElem[i].setAttribute('title', '另開新視窗-' + title);
            }
          }
        }, 1000);

      });
    }

    @Watch('route.query.keyword')
    fetchData(keyword: string) {
      this.keyword = keyword
      this.changePage(1)
    }

    gets(queryString?: any) {
      this.restService.gets(queryString).then((data: any) => {
        this.page = data
      })
    }

    changePage(page: number) {
      this.gets({keyword: this.keyword, page: page})
    }
  }
