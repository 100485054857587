
  import { Vue } from 'vue-class-component';
  import { useRouter, useRoute } from 'vue-router'
  import { QA } from "./qa/qa";

  export default class QADetailView extends Vue {
    router = useRouter()
    route = useRoute()

    id: string = this.route.params.id as string
    item = QA.getData(parseInt(this.id))
  }
