
  import { Options, Vue } from 'vue-class-component'
  import {Form, Field, ErrorMessage, useField, useForm, useFieldArray} from 'vee-validate'
  import { toTypedSchema } from '@vee-validate/yup'
  import { useReCaptcha } from 'vue-recaptcha-v3'
  import * as yup from 'yup';
  import { RestService } from "../service/rest.service"
  import {useRoute, useRouter} from "vue-router";

  @Options({
    components: {
      Form,
      ErrorMessage
    },
  })
  export default class Appointment2View extends Vue {
    router = useRouter()
    route = useRoute()
    reCaptcha = useReCaptcha()
    restService = new RestService('appointment')

    disableButton = false

    schema = toTypedSchema(
      yup.object({
        name: yup.string().required(),
        mobile: yup.string().min(10),
        email: yup.string().required().email(),
        category: yup.string().required(),
        content: yup.string().required()
      })
    )

    vForm = useForm({
      validationSchema: this.schema
    })

    name = useField<string>('name')
    mobile = useField<string>('mobile')
    email = useField<string>('email')
    category = useField<string>('category')
    content = useField<string>('content')

    form: any = {
      name: this.name.value,
      mobile: this.mobile.value,
      email: this.email.value,
      category: this.category.value,
      content: this.content.value
    }

    submit = this.vForm.handleSubmit(async (values: any, actions: any) => {
      this.disableButton = true
      await this.reCaptcha?.recaptchaLoaded()
      const token = await this.reCaptcha?.executeRecaptcha('/appointment2')
      this.restService.recaptcha(token!).then((data: any) => {
        if (data.result) {
          this.restService.create(this.form).then((data: any) => {
            if (data.result) {
              this.router.push({
                name: 'appointment'
              }).catch(() => this.disableButton = false)
            }
            alert(data.message)
          }).finally(() => this.disableButton = false)
        } else {
          alert('人機驗證驗證失敗，建議您關閉瀏覽器重新一次或與窗口聯繫。')
        }
      }).catch(() => this.disableButton = false)
    })

    mounted() {
      this.form = Object.assign(this.form, this.route.query)
    }

    backEdit() {
      this.router.push({
        name: 'appointment',
        query: this.form
      })
    }
  }
