
  import { Options, Vue } from 'vue-class-component';
  import PaginationComponent from '@/components/pagination/PaginationComponent.vue';
  import { RestService } from "../service/rest.service";
  import { Emit, Prop, Ref, Watch } from 'vue-property-decorator'

  @Options({
    components: {
      PaginationComponent
    }
  })
  export default class FundView extends Vue {

    restService = new RestService('funds')
    searchForm: any = {
      location: '',
      fundType: '',
      amount: '',
      object: ''
    }
    items: any = []
    totalPages = 0

    showSelect = {
      location: false,
      fundType: false,
      amount: false,
      object: false
    }

    locations = {
      '': '全部地區',
      ALL: '全國性',
      HSH: '新竹縣'
    }

    fundTypes = {
      '': '不限',
      CREDIT: '融資貸款',
      SUBSIDY: '補助補貼',
      FUND: '創投資金',
      OTHER: '其他'
    }

    amounts = {
      '': '不限',
      L1: '100萬(含)以下',
      L2: '100萬-300萬(含)以下',
      L3: '300萬-1000萬',
      L4: '超過1000萬'
    }

    objects = {
      '': '不限',
      COMPANY: '一般企業',
      STUDENT: '學生',
      ABORIGINES: '原住民',
      HAKKA: '客庄',
      OTHER: '其他'
    }

    mounted() {
      this.gets()
    }

    gets(queryString?: any) {
      this.restService.gets(queryString).then((data: any) => {
        this.items = data
        this.totalPages = data.totalPages
      })
    }

    changePage(page: number) {
      this.searchForm.page = page
      this.gets(this.searchForm)
    }
  }
