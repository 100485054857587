
import {Options, Vue} from 'vue-class-component';
import { useRouter, useRoute } from 'vue-router'
import $ from 'jquery';
import {ProgressBar} from "@marcoschulte/vue3-progress";
import '@marcoschulte/vue3-progress/dist/index.css'

  @Options({
    components: {
      ProgressBar
    },
  })
  export default class AppView extends Vue {

    router = useRouter()

    form: any = {keyword: ''}

    mounted() {
      //Scroll to Top
      const btnTop = '.btn-top';

      $(document).on('click', btnTop, function(evt) {
        evt.preventDefault();
        $('body,html').animate({
          scrollTop: 0
        }, 500)
      });

      $(window).on('scroll', function() {
        const $this:any = $(this)
        if ($this.scrollTop() > 200) {
          $(btnTop).fadeIn();
        } else {
          $(btnTop).fadeOut();
        }
      });

      this.$nextTick(() => {

        const refreshIntervalId = setInterval(() => {

          const iframes = [...document.getElementsByTagName('iframe')];
          iframes.filter((value, index) => value.getAttribute('title') == '').forEach((iframe, index) => {
            iframe.setAttribute('title', 'iframe' + index);
          });

          let recaptchaResponse = document.getElementById('g-recaptcha-response');
          recaptchaResponse?.setAttribute('title', 'g-recaptcha-response');

          let grecaptchaBadge = document.getElementsByClassName('grecaptcha-badge');
          grecaptchaBadge[0]?.nextElementSibling?.setAttribute('title', 'grecaptcha-iframe');
          
          console.log(new Date());
        });
        setTimeout(() => {
          clearInterval(refreshIntervalId);
          console.log('stop');
        }, 5000);

      });

    }

    handleSubmit() {
      if (this.form.keyword !== '') {
        this.router.push({
          name: 'result',
          query: {
            keyword: this.form.keyword
          }
        });
      }
    }

    redirectTo(to: string) {
      this.router.push( {
        path: to
      })
    }
  }
