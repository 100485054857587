<template>
  <main id="main" v-title="'青創基地'" class="sec-main">
    <section class="startup-main">
      <div class="container-lg">
        <div class="col-sm-10 mx-auto">
          <nav style="--bs-breadcrumb-divider: '';" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link to="/" title="首頁" class="foo: false">首頁</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">資源搜尋 - 青創基地</li>
            </ol>
          </nav>
        </div>
        <div>
        <div class="row mb-4 startup-into1">
          <h1 class="visually-hidden">青創基地</h1>
          <div class="col-11 mx-auto me-lg-0">
            <h2 class="h2 text-primary mb-md-4 mb-2">青創基地介紹</h2>
          </div>
          <div class="col-11 col-sm-10 col-lg-6 offset-lg-1 col-xl-5 mx-auto me-lg-0">
              <div class="mb-4">
                <p class="h4">
                  為匯集青年創意及創造力，激發青年創新創業能量，協助有志青年於本縣圓創業夢想而設立此基地位於明新科技大學內，占地280坪，內設有共享辦公空間、新創辦公室、共享學習教室、聯合會議室、小型會議室、洽談室等空間，並邀請專家學者提供創業諮詢輔導、辦理各類培力課程、媒合會、成果博覽會等，提升青年創業各階段面臨問題的解決能力，也加速提升個人及企業競爭能力，並打造多元化之創新創業聚落。
                </p>
              </div>
              </div>
            <div class="col-11 col-sm-10 col-lg-5 col-xl-6 frsc justify-content-lg-end mx-lg-0 mx-auto startup-into-img">
              <div class="startup-hub-logo text-center mb-5 mb-lg-0">
                <picture>
                  <img class="img-fluid" src="resources/images/banner-startupHub.svg" alt="">
                </picture>
              </div>
            </div>
          </div>
          <div class="row mb-4 startup-into2">
            <div class="col-11 mx-auto me-lg-0">
                <h2 class="text-primary mb-md-4 mb-2">申請對象資格</h2>
            </div>
            <div class="col-11 col-sm-10 col-lg-6 offset-lg-1 col-xl-5 mx-auto me-lg-0">
              <div class="mb-5">
                <p class="h4">
                  具創新應用產品或服務開發能力及創新經營構想之個人、團隊、公司行號或企業，<br>
                  並具備以下條件者(其中(三)-(六)項符合其中一項即可)，得申請進駐(請提供佐證資料)。<br><br>
                  (一) 團隊成員至少1人須45歲(含)以下。(必要條件)<br>
                  (二) 團隊成員至少1人設籍新竹縣或於新竹縣工作。(必要條件)<br>
                  (三) 登記於新竹縣之公司、行號及工廠。<br>
                  (四) 國外或其他縣市具一定規模之創新企業有意在新竹縣拓展據點者。<br>
                  (五) 從事相關促進產業創新與創業組織、團隊。<br>
                  (六) 其他經新竹縣政府教育局同意進駐之創業團隊。
                </p>
              </div>
            </div>
            <div class="col-11 col-sm-10 col-lg-5 col-xl-6 frcc align-items-xl-end justify-content-lg-end mx-lg-0 mx-auto startup-into-img">
              <picture>
                <img srcset="resources/images/illustration/startup@sm.svg" alt="" class="d-block img-fluid">
              </picture>
            </div>
          </div>
        </div>
        <div class="row mb-5 pt-md-5 pt-0">
          <div class="col-11 col-sm-10 mx-auto">
            <div class="mb-4">
              <h2 class="h2 text-primary mb-md-4 mb-2">進駐廠商</h2>
              <div class="row row-cols-2 row-cols-sm-3 row-cols-md-4 company-list">
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/company19@2x.png" alt="TKS FULL HOUSE提克斯國際餐飲">
                  </div>
                  <div class="company-card-name info-text mt-2">TKS FULL HOUSE<br>提克斯國際餐飲</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/2024/大崎文史工作室.jpg" alt="大崎文史工作室">
                  </div>
                  <div class="company-card-name info-text mt-2">大崎文史工作室</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/company14@2x.png" alt="日日風味所">
                  </div>
                  <div class="company-card-name info-text mt-2">日日風味所</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/2024/伍柿山蟻窩文化工作室.jpg" alt="伍柿山蟻窩文化工作室">
                  </div>
                  <div class="company-card-name info-text mt-2">伍柿山蟻窩文化工作室</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/2024/宏力國際股份有限公司.jpg" alt="宏力國際股份有限公司">
                  </div>
                  <div class="company-card-name info-text mt-2">宏力國際股份有限公司</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/2024/那裏文化.jpg" alt="那裏文化">
                  </div>
                  <div class="company-card-name info-text mt-2">那裏文化</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/company18@2x.png" alt="明新學校財團法人明新科技大學">
                  </div>
                  <div class="company-card-name info-text mt-2">明新學校財團法人<br>明新科技大學</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/company5@2x.png" alt="林太太健康零食舖">
                  </div>
                  <div class="company-card-name info-text mt-2">林太太健康零食舖</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/company16@2x.png" alt="果多文創有限公司">
                  </div>
                  <div class="company-card-name info-text mt-2">果多文創有限公司</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/2024/哇哈哈劇團.jpg" alt="哇哈哈劇團">
                  </div>
                  <div class="company-card-name info-text mt-2">哇哈哈劇團</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/2024/英式設計有限公司.jpg" alt="英式設計有限公司">
                  </div>
                  <div class="company-card-name info-text mt-2">英式設計有限公司</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/company13@2x.png" alt="風城行銷顧問有限公司">
                  </div>
                  <div class="company-card-name info-text mt-2">風城行銷顧問有限公司</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/2024/時尚爵士數位影音有限公司.jpg" alt="時尚爵士數位影音有限公司">
                  </div>
                  <div class="company-card-name info-text mt-2">時尚爵士數位影音有限公司</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/company11@2x.png" alt="珩衍創意執行(股)公司">
                  </div>
                  <div class="company-card-name info-text mt-2">珩衍創意執行(股)公司</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/company9@2x.png" alt="祐鵬健康科技有限公司">
                  </div>
                  <div class="company-card-name info-text mt-2">祐鵬健康科技有限公司</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/company15@2x.png" alt="捻花惹草工作室">
                  </div>
                  <div class="company-card-name info-text mt-2">捻花惹草工作室</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/2024/匯百川科技有公司.jpg" alt="匯百川科技有限公司">
                  </div>
                  <div class="company-card-name info-text mt-2">匯百川科技有限公司</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/company2@2x.png" alt="溫室氣體盤查顧問服務團隊">
                  </div>
                  <div class="company-card-name info-text mt-2">溫室氣體盤查顧問服務團隊</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/company1@2x.png" alt="嘉姬黑豬農產品">
                  </div>
                  <div class="company-card-name info-text mt-2">嘉姬黑豬農產品</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/2024/酷瘋國際有限公司.jpg" alt="酷瘋國際有限公司">
                  </div>
                  <div class="company-card-name info-text mt-2">酷瘋國際有限公司</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/2024/燁星國際行銷有限公司.jpg" alt="燁星國際行銷有限公司">
                  </div>
                  <div class="company-card-name info-text mt-2">燁星國際行銷有限公司</div>
                </div>
                <div class="col company-card fccc mb-5">
                  <div class="company-card-logo">
                    <img class="img-fluid" src="resources/images/logo/company/company7@2x.png" alt="鴻檸國際行銷有限公司">
                  </div>
                  <div class="company-card-name info-text mt-2">鴻檸國際行銷有限公司</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-11 col-sm-10 mx-auto">
            <div class="mb-4">
              <h2 class="h2 text-primary mb-md-5 mb-3">場地租借</h2>
            </div>
          </div>
          <div class="col-12 col-sm-10 mx-auto">
            <div class="row hub-space-group row-cols-1 row-cols-sm-2">
              <div class="col mb-5">
                <div class="h3 text-primary text-center mb-2">共享學習教室</div>
                <img src="resources/images/logo/company/startup-space1@2x.png" alt="共享學習教室" class="img-fluid">
                <div class="bg-primary d-sm-none mb-4">
                  <h3 class="text-white px-3 py-2">空間可容納24人<br>300元/每時段</h3>
                </div>
              </div>
              <div class="col mb-5">
                <div class="h3 text-primary text-center mb-2">聯合會議室</div>
                <img src="resources/images/logo/company/startup-space2@2x.png" alt="聯合會議室" class="img-fluid">
                <div class="bg-primary d-sm-none">
                  <div class="h3 text-white px-3 py-2 mb-4">空間可容納10人<br>300元/每時段</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-11 col-sm-10 mx-auto">
            <p class="h4">
              ・ 共享學習教室：空間可容納24人，每時段300元；聯合會議室：空間可容納10人，每時段300元。<br>
              ・上述相關空間（會議室、共享學習教室），短期使用者（每週租用不超過2次，每次不超過3小時者），均可視進駐空間實際使用狀況開放免費使用，但必須事先申請。<br><br>
              ・若有長期使用需求者，以上之租金費用收取，本項租金為空間包場費用，非單獨使用一席之費用。美食隊以四小時為一基數，未滿四小時者以四小時計算；活動逾時一小時以上未超過四小時者，加收一個時段之費用。<br><br>
              ・特殊設備、基地附設之室內與戶外展覽空間租用，專案協商辦理。<br><br>
              ・新竹縣政府及新竹縣政府教育局辦理之活動，免收場地租金。公務機關、學校及各青創基地合作單位辦理之創新創業性質公益活動，得免收或減收場地租金。
            </p>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-11 col-sm-10 mx-auto">
            <div class="mb-4">
              <h2 class="h2 text-primary mb-md-4 mb-2">聯絡資訊</h2>
            </div>
            <p class="h4">
              官方網站：<a href="https://startup-hc.tw" title="另開新視窗" target="_blank" rel="noopener noreferrer"><u>https://startup-hc.tw</u></a><br>
              聯絡電話：(03)5593142#3515、3504、1400<br>
              地點：30401新竹縣新豐鄉新興路1號 商學館1F<br>
              E-mail：icc@must.edu.tw
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-11 col-sm-10 mx-auto">
            <div class="mb-4">
              <h2 class="h2 text-primary mb-md-4 mb-2">所屬單位</h2>
            </div>
            <p class="h4">
              指導單位：新竹縣政府、新竹縣議會<br>
              主辦單位：新竹縣政府教育局<br>
              執行單位：明新學校財團法人明新科技大學
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script setup lang="ts">
</script>