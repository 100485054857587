
import {Options, Vue} from 'vue-class-component'
import {ErrorMessage, Form, useField, useForm} from 'vee-validate'
import {toTypedSchema} from '@vee-validate/yup'
import * as yup from 'yup';
import {RestService} from "../service/rest.service"
import {useRoute, useRouter} from "vue-router";

@Options({
  components: {
    Form,
    ErrorMessage
  },
})
export default class AppointmentView extends Vue {
  router = useRouter()
  route = useRoute()
  restService = new RestService('appointment')

  disableButton = false

  schema = toTypedSchema(
      yup.object({
        name: yup.string().required(),
        mobile: yup.string().min(10),
        email: yup.string().required().email(),
        category: yup.string().required(),
        content: yup.string().required()
      })
  )

  vForm = useForm({
    validationSchema: this.schema
  })

  name = useField<string>('name')
  mobile = useField<string>('mobile')
  email = useField<string>('email')
  category = useField<string>('category')
  content = useField<string>('content')

  form: any = {
    name: this.name.value,
    mobile: this.mobile.value,
    email: this.email.value,
    category: this.category.value,
    content: this.content.value
  }

  submit = this.vForm.handleSubmit(async (values: any, actions: any) => {
    console.log(this.form);
    let f = this.form
    this.disableButton = true
    await this.router.push({
      name: 'appointment2',
      query: f
    }).catch(() => this.disableButton = false)
  })

  mounted() {
    this.form = Object.assign(this.form, this.route.query)
  }
}
