
export class QA {

  static getData(id: number) {
    return this.data.filter(d => d.id === id)[0]
  }

  static getDatas() {
    return this.data
  }

  static data = [
    { id: 1, title: '我可以一邊上班一邊創業嗎？上班族兼職創業有什麼要注意的？', content: '<p>\n' +
        '              現在有許多上班族，想要增加額外收入而創業，接下來說說有哪些要注意的地方！<br>\n' +
        '            </p>\n' +
        '            <ul style="list-style-type: decimal;">\n' +
        '              <li>勞健保：若創業初期規模小，是一人事業，建議勞健保維持在現職公司，等營運上軌道擴大營運後再考慮自設勞健保單位</li>\n' +
        '              <li>所得稅：若只是自己接案，想經營人脈同時賺賺外快，要記得兼職所得每次收入超過84,500元時，需繳納收入全額5%的所得稅喔！</li>\n' +
        '              <li>營業秘密：在職創業要注意自己事業和公司業務的界線 ，小心別被誤會利用公司資源私下營利；就職契約、營業秘密及競業條款都要留意</li>\n' +
        '            </ul>\n' +
        '            <br><br>\n' +
        '            <p>兼職創業除要了妥善規劃，同時要在現有的工作及角色中間取得平衡，等到營收進入成長期，就可以考慮專注經營自己的事業囉！</p>'},
    { id: 2, title: '想知道政府是否有提供相關學習管道，讓我可以了解外銷的眉角。', content: '<ul style="list-style-type: decimal;">\n' +
        '                <li>行政院新創基地：每周都會開設不同主題的講座活動，來這裡與其他創業夥伴互動，跟講師面對面交流！</li>\n' +
        '                <li>中小企業網路大學校：免費的線上學習管道，提供海外市場行銷等等創業相關課程，讓忙碌的你，不用受限時間與地點，就可以網路上課。</li>\n' +
        '              </ul>\n' +
        '              <br><br>\n' +
        '              <p>除此之外，經濟部國貿局也有很多相關的輔導與行銷資源喔！相關資訊請參考<a href="https://www.trade.gov.tw/" title="另開新視窗" target="_blank" rel="noopener noreferrer">https://www.trade.gov.tw/</a></p>'},
    { id: 3, title: '除了創業課程，還有什麼其他提供給學生的資源？', content: '<p>\n' +
        '                針對大專院校學生，政府有特別設立Ustart創新創業計畫，獲選團隊除了有獎金，還可以獲得第一階段六個月及第二階段一年的培育輔導，讓尚未成熟的創業點子經由專業師資指導得以實現。另外創新創業激勵計畫，規劃了創業核心課程並邀請業界講師經驗傳承，幫助團隊與市場接軌，還能透過投資交易媒合會吸引創投獲得創業第一桶金。還有大專校院創新創業扎根計畫，協助學生於在校期間進行創業培訓並組成校園創業團隊，藉由創業管理學習及募資實際演練，修正創新構想並養成問題解決的能力，成為有實力的青年創業家。\n' +
        '              </p>\n' +
        '              <p>現在許多學校也有成立創新育成中心，開辦創業學程、整合校友資源、設立輔導機制，讓創業這條路走起來一點也不孤單。除了校內，業界也有許多精彩的創業系列活動可以參加，像StartupWeekend、WorkFace 等，說不定還可以遇見與你志同道合的創業夥伴！</p>'},
    { id: 4, title: '想要創業，可是廠房難找，政府有提供什麼資源或服務嗎？', content: '<p>\n' +
        '                歡迎參考經濟部工業局-台灣工業用地供給與服務資訊網，獲取土地、廠房的出租與出售資訊。台灣工業用地供給與服務資訊網為促進工業區 (以編定工業區為主) 資訊之交流平台，提供工業用地供需雙方免費登錄資訊的服務，歡迎創業者多加利用：<a href="https://idbpark.moeaidb.gov.tw/" title="另開新視窗" target="_blank" rel="noopener noreferrer">https://idbpark.moeaidb.gov.tw/</a>\n' +
        '              </p>'},
    { id: 5, title: '政府如何解決創業青年尋找創業空間之需求？', content: '<p>\n' +
        '                經濟部中小企業處「新創圓夢網」的創育機構專區提供創業場域等資訊，包含共同工作空間、創新育成中心、公有可用空間等，另也提供民間單位刊載共同工作空間，使用者可註冊網站會員後，經審核空間後即可。相關資訊請逕洽網站查詢<a href="http://sme.moeasmea.gov.tw/startup/" title="另開新視窗" target="_blank" rel="noopener noreferrer">http://sme.moeasmea.gov.tw/startup/</a>。\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                公共工程委員會「閒置公共設施通報及公告平臺」：為避免各機關隱匿不報所管轄之閒置公共設施，同時突破以往由機關自行清查提報之作法，建立通報平臺以化被動為主動，提供民眾免付費檢舉專線及平臺網站，進行疑似閒置設施案件通報。此外，每月於網站公布閒置設施資訊、每季公布相關機關改善情形，以督促機關改善閒置場所。相關資訊請逕洽平臺網站查詢<a href="http://cmdweb.pcc.gov.tw/pccms/morac/idle_geoeng.pasin" title="另開新視窗" target="_blank" rel="noopener noreferrer">http://cmdweb.pcc.gov.tw/pccms/morac/idle_geoeng.pasin</a> 。\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                財政部國有財產署「國有非公用擬讓售房地資訊公開」：為協助青年築夢創業，創業青年若需國有非公用房地，可洽各創業貸款或輔導計畫主辦部會，協助審認及出具認定使用必要之證明文件，即可向國產署申請以承租方式取得使用權。另外創業青年也可主動向國產署各分署（辦事處）申請辦理標租，以利取得所需要的創業空間。相關資訊請逕洽該署網站查詢<a href="http://www.fnp.gov.tw/" title="另開新視窗" target="_blank" rel="noopener noreferrer">http://www.fnp.gov.tw/</a> 或電洽免費服務電話：0800-357-666。\n' +
        '              </p>'},
    { id: 6, title: '於創業初期，因為有些比賽是需要團隊才可以參加，有些比賽是需要公司設立有立案才可以參加比賽，那請問立案的時機點？', content: '<p>\n' +
        '                有這樣的問題，表示說大家已經開始學習怎麼樣做一個創業家，這也是每個創業家都會遇到的問題，目前有一種競賽叫創意競賽，就是專門提供給創業團隊的，既然都叫創意競賽，那原則上就是未登記立案的公司參加；但是創業競賽就不一樣了，比如說成立一年內的公司，就屬於已創業的公司，即符合參與比賽的資格。另外建議也可以往公司法，也就是法律這部分去瞭解，註冊公司是在所難免的，創業家要一步一步去學習，發現問題之後再來解決，創業過程功課做得越多、懂得越透徹，就越容易克服困難，從中慢慢建立資源，比如法律上的資源，有哪些可以免費諮詢，這些方面可以多去了解。\n' +
        '              </p>'},
    { id: 7, title: '創業初期面臨資金週轉、產品行銷問題如何解決？如遇到瓶頸有什麼建議？', content: '<p>\n' +
        '                玉豐海洋科儀股份有限公司張乃仁總經理表示，他在創業時遇到最大的問題是資金的預留，無論研發成本、機具設備的報廢等，對公司都是一筆龐大的支出，這些支出在事發前無法確實掌握，但創業家必須培養自己能夠確實掌握資金流動的能力，公司才能持續下去。建議青年創業家善用政府及專業顧問指導團隊的資源，讓有益的資源作合理的分配並發展其最大的效益。 想創業但不幸的發現資金不夠寬裕時，給奇創造股份有限公司高協聖總經理建議，應該重新定義產品，並思考該產品定位，將產品申請專利或找其他人共同合作，都是可初步解決資金缺口的方式。創業者的產品很棒但都沒有賞識的伯樂，這也是種創業瓶頸，這時創業家不能坐等機會降臨，大部分人會選擇存夠一筆退休金後再創業，這是消極的想法，因為你的產品可能只有一點缺憾，可很快修正改善，亦或只是推銷產品的紅娘不好，只需要換一位專業的推銷員；也有一部分人會先擱置產品，到大公司去工作3-5年，累積人脈之後再來創業，創業時轉向身邊這群經年累積起來的人脈推銷，他們了解你的人格特質及能力，就會來支持你，因此將產品延後五年推出，也是一個方法。\n' +
        '              </p>'},
    { id: 8, title: '全球首富比爾蓋茲國高中階段便開始創業，如何使我們的學生能在就學階段便能充分了解政府的有哪些資源可利用？', content: '<p>\n' +
        '                創業計畫資源<br>\n' +
        '                (一)創業諮詢服務：經濟部中小企業處提供免費創業諮詢服務，任何青年朋友皆可撥打諮詢專線0800-589-168詢問創業相關問題。<br>\n' +
        '                (二)知能養成：藉由中小企業網路大學校平台提供線上學習資源、實體課程培訓、活動資訊等一站式企業學習服務，整合網實學習資源，提供多元化的新學習模式，推動企業組織學習，協助企業培育專業人力，並辦理實體課程，提升中小企業高階領導人、傳承接班等所需知能與企業人力培育能量。<br>\n' +
        '                (三)創業教育扎根：教育部提供多項以大學校院學生為主的計畫，例如：大專校院創新創業教育計畫、U-start創新創業計畫，結合業師輔導、校園創業資金投入、研發技術支援、創業後育成等校園活動到設立大學校院創業中心，使創新創業文化在校園扎根，幫助青年朋友提升創業知識及能力。\n' +
        '              </p>'},
    { id: 9, title: '離開學校後，還有什麼管道可以進修創業相關知能？', content: '<p>\n' +
        '                經濟部中小企業處為加強青年創業動能，規劃「中小企業網路大學校」(<a href="www.smelearning.org.tw" title="另開新視窗" target="_blank" rel="noopener noreferrer">www.smelearning.org.tw</a>)之創業育成學院，內容包含創業點子與市場驗證、市場創新面－行銷、人資、營運、財務、創業風險等多元主題，另也有針對跨境電商、亞洲矽谷與新南向政策的課程內容，深入充實創業應備經營管理技能。\n' +
        '              </p>'},
    { id: 10, title: '青年創業及啟動金貸款有分為準備金及開辦費、週轉性支出及資本性支出三種用途，申請時分別需要準備哪些資料呢？', content: '<p>\n' +
        '                準備金及開辦費：<br>\n' +
        '                貸款額度最高為新臺幣200萬元<br>\n' +
        '                依法完成公司、商業登記或立案後8個月內提出申請，涵蓋裝潢費、租押金、設備器材費用、雜支等，以估價單、購買憑證、或是和廠商簽訂的合約提出申請\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                週轉性支出：<br>\n' +
        '                貸款額度最高為新臺幣400萬元<br>\n' +
        '                設立五年內，提供營業所需週轉性支出，例如員工薪資、進出貨款、週轉預備金，需準備營運3個月以上的財務報表\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                資本性支出：<br>\n' +
        '                貸款額度最高為新臺幣1200萬元<br>\n' +
        '                設立五年內，為購置(建)或修繕廠房、營業場所、相關設施、營運所需機器、設備及軟體等所需之資本性支出，以估價單、購買憑證、或是和廠商簽訂的合約提出申請\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                青創啟動金申請詳細資訊<a href="https://sme.moeasmea.gov.tw/startup/modules/funding/detail/index.php?sId=15" title="另開新視窗" target="_blank" rel="noopener noreferrer">https://sme.moeasmea.gov.tw/startup/modules/funding/detail/index.php?sId=15</a>\n' +
        '              </p>'},
    { id: 11, title: '我們公司想要申請政府的創業補助，該如何提高創業補助過件率呢？', content: '<p>提高創業補助過件率的5大重點，一次搞懂 !</p>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>看得懂的計畫書：撰寫計畫書時須注意前後邏輯、語句通暢度、重點圖文呈現，撰寫評委看得懂的計畫書內容。</li>\n' +
        '                <li>清晰的商業模式：無論是創業營運、先期研究、研究開發或加值應用，各階段的商業模式都要清晰，評委的評估重點也是要確認這個計畫能夠營運賺錢，增加更多產值。</li>\n' +
        '                <li>創新點：為什麼要做這個計畫? 要解決什麼問題? 解決問題的策略方法和過去有什麼不同? 無論是提升效率、增加價值、降低成本...都屬創新範疇。</li>\n' +
        '                <li>可行性：完整盤點內外風險以及可行性，包含市場、商業模式、技術、團隊、財務、時間。 </li>\n' +
        '                <li>量化查核點：把相關軟體、硬體功能與技術規格說明清楚，並加上驗證查核方法的說明。</li>\n' +
        '              </ul><br>\n' +
        '              <p>\n' +
        '                大部分的補助計畫非全額補助，要花了錢後，執行達到計畫目標並且有符合計畫內容、政府規定的核銷單據才可核銷喔! \n' +
        '              </p>\n' +
        '              <p>\n' +
        '                註：本文引用自新創圓夢網陳雍華顧問專欄文章《申請創業補助的眉角》，更多詳細內容歡迎可參考：<a href="https://taiwangov.com/ezAu0" title="另開新視窗" target="_blank" rel="noopener noreferrer">https://taiwangov.com/ezAu0</a>\n' +
        '              </p>'},
    { id: 12, title: '想順利通過銀行貸款審核有什麼要注意的？', content: '<p>新創企業要開始營運除了需準備自有資金，往往也可能面臨資金不足需要向銀行申辦創業貸款的狀況，但是貸款畢竟是有還款壓力的，因此銀行會透過以下的5P授信原則來評估申貸者的情況及條件：</p>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>借款戶People：經營團隊、負責人等資歷及履約能力。 </li>\n' +
        '                <li>資金用途Purpose：資金運用是否合情、合理、合法，與計畫可否實施及達成。</li>\n' +
        '                <li>還款來源Payment：產品(服務)是否具有市場性、競爭性及未來性。</li>\n' +
        '                <li>債權保障Protection：人保、物保及善用「信保基金」承保。 </li>\n' +
        '                <li>授信展望Perspective：經營事業之發展性，預測未來風險及利益。</li>\n' +
        '              </ul><br>\n' +
        '              <p>\n' +
        '                在提出申請前，除了透過5P原則檢視自己是否能獲得銀行信任外，也提醒創業者在財務方面須保持清楚的帳務、報稅紀錄及良好的債權信用，才能提高核貸機會喔！\n' +
        '              </p>'},
    { id: 13, title: '聽說後續如果想申請政府的創業資金，會需要撰寫計畫書，究竟要寫些甚麼呢？', content: '<p>\n' +
        '                對於想創業的朋友來說，創業計畫書不僅可協助盤點所需的資源，也能將經營管理、行銷、財務等方向做更有條理的規劃。在撰寫方面，可先設定好企業目標及理念等、接著進行資料蒐集，資料備齊開始撰寫後可再依實際營運狀況或申請項目做修改補強。\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                在內容配置方面，主要需具備以下內容： \n' +
        '              </p>\n' +
        '              <ul>\n' +
        '                <li>企業基本資料：事業名稱、事業組織型態、資本額、主要營業項目、設立時間、公司及負責人聯繫方式、員工人數等。</li>\n' +
        '                <li>經營現況：公司產品或服務簡介、產品或服務之用途及特點、現有或潛在的客源、經營團隊介紹、設備及資本規劃。</li>\n' +
        '                <li>市場分析：以 S.W.O.T.分析、行銷4P-產品、通路、價格、促銷等分析方式。</li>\n' +
        '                <li>財務分析：可就資金結構、收入來源、資金使用方式及比例、甚至是財務預測作詳盡說明，如已實際營運也可附上公司損益表、資產負債表、現金流量表等。</li>\n' +
        '              </ul><br>\n' +
        '              <p>\n' +
        '                至於政府的政策性資金大致可分為創業貸款、獎勵補助及創投資金等類別，若想看更多細節，歡迎可上 #新創圓夢網「資金快搜」專區；若在撰寫過程中需要更多知識補給，也歡迎可多利用 #中小企業網路大學校 多元的免費線上課程。 若想透過一些新的模式來做發想，也可參考 #商業模式九宮格 來規劃，或許會激盪出更多不同的創意！\n' +
        '              </p>'},
    { id: 14, title: '聽說獲貸後要有利息補貼的話，每年定期要上網登記，請問要去哪裡登記呢？', content: '<p>\n' +
        '                假設於109年10月獲貸青年創業及啟動金貸款，且獲利息補貼期間為5年，則依次須於110年~115年的5月底前至融資效益登錄平台完成登記。\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                依照貸款利息補貼程序，承貸金融機構於當年度12月31日以前撥貸之借款人，須於次年5月底以前至指定平臺登錄，並於利息補貼期限到期後的次年5月底以前，均須辦理登錄。 \n' +
        '              </p>\n' +
        '              <p>\n' +
        '                換句話說，在利息補貼期間及到期後一年內，只要到了要申報營所稅的五月，獲貸者就要記得上融資效益登錄平台填寫營運相關資料囉！\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                青年創業及啟動金貸款融資效益登錄平台 ：<a href="https://pse.is/3eyaxl " title="另開新視窗" target="_blank" rel="noopener noreferrer">https://pse.is/3eyaxl </a>\n' +
        '              </p>'},
    { id: 15, title: '如果我的產品想透過群眾募資來完成，該注意什麼事情呢？', content: '<p>\n' +
        '                「群眾募資」的概念與市場在台灣的發展已經將近10年了，根據《2020年台灣群眾集資報告》，2020年全臺各大平台和獨立集資的所有募資專案的總金額，合計約有25億左右，並且約有100萬人參與了贊助，對比前幾年的數據來說，可算是還在不斷地增長中。\n' +
        '                <br><br>\n' +
        '                而要開始募資專案前，一定要先思考的三個問題分別是：\n' +
        '                <br><br>\n' +
        '                募資專案的內容是什麼？\n' +
        '                <br><br>\n' +
        '                預計尋找什麼樣的人來贊助？\n' +
        '                <br><br>\n' +
        '                用什麼方式來推廣給他們？\n' +
        '                <br><br>\n' +
        '                這三個問題和募資的產品、對象及行銷方式有關，若只是因為沒有資金想找錢開發產品而沒有自己的特色及規劃，可能非常容易失敗喔！\n' +
        '                <br><br>\n' +
        '                這邊也和大家分享群眾募資成功的三大元素，一個成功的募資專案，往往都會滿足下面三個重要元素：\n' +
        '              </p>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>功能面 你的產品、服務，或是活動，它們具體解決了什麼問題?或滿足了人們怎樣的需要?以及其背後有哪些專業或技術背書、規格、屬性等。</li>\n' +
        '                <li>情緒面 人們通常對這樣的募資主題、方案內容中的產品/服務所感興趣的原因?以及背面所隱含的情感是什麼?</li>\n' +
        '                <li>社會面 一個募資活動反映了目前社會的哪些趨勢潮流？代表了怎樣的價值觀？由於人們參與募資的原因，不只是在預購一項產品或服務，有時候購買行為更是一種認同，或透過贊助你的產品來展現某種價值觀。</li>\n' +
        '              </ul><br>\n' +
        '              <p>\n' +
        '                以上這三個重要的元素，往往會依照募資專案的內容和屬性來調整，在做募資策劃時我們需要先把這些背後的原因找出來，並且在方案設計、募資影片、募資頁面文案，及所有行銷活動裡，都必須被強調出來。在設計一個募資專案時，不能只考慮到功能面或只講述產品或服務本身的優點及特色而已，更要挖掘出主題出背後的情緒面，以及反映出近期社會大眾關心的議題，才能夠提高募資成功的機率喔！\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                註：本文引用自新創圓夢網康晉暚顧問專欄文章&lt;如何做好一個「群眾募資專案」的行銷工作&gt;，更多詳細內容歡迎可參考：<a href="https://taiwangov.com/v236k" title="另開新視窗" target="_blank" rel="noopener noreferrer">https://taiwangov.com/v236k</a>\n' +
        '              </p>'},
    { id: 16, title: '資金獲取的管道有好多種，要怎麼知道哪一種比較適合我？', content: '<p>\n' +
        '                資金來源大致可以分為政府補助、銀行融資、投資募資三個種類，來看看這三種資金的差異在哪邊！\n' +
        '              </p>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>政府補助：雖然資金金額較小，但是耗費的時間跟成本較低，適合在有新產品要開發，需要小額資金時申請，而且沒有還款壓力！</li>\n' +
        '                <li>銀行融資：金額較政府補助高，申請時間約1-2個月，資金可以用在公司營運或添購設備時，但要記得準時繳交利息及返還本金</li>\n' +
        '                <li>投資募資：可以獲得較大筆的資金，不過要三個月到半年的時間，才有辦法取得資金，日後也要付出比銀行融資利息還要多的利潤給投資者，適合已經有營運基礎想擴大營運規模或市場開發的新創</li>\n' +
        '              </ul><br>\n' +
        '              <p>想了解更多創業資金的資訊，就在找資金專區或撥打創業諮詢服務專線0800-589168，預約顧問諮詢！</p>'},
    { id: 17, title: '除了群眾募資，也能向天使投資人跟創投基金獲取資金，這兩者有什麼不同？', content: '<p>\n' +
        '                天使投資人跟創投基金不只投資時間點不同，承受的風險及介入公司營運程度也不一樣，小編就用列點的方式簡單跟大家說明差異吧！<br>\n' +
        '              </p>\n' +
        '              <span>天使投資人</span>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>投資時機點：在新創剛設立時，看見企業或產品的發展潛力</li>\n' +
        '                <li>介入程度：較低，以顧問的角色運用資金及人脈協等，協助新創企業</li>\n' +
        '              </ul>\n' +
        '              <br>\n' +
        '              <span>\n' +
        '                創投基金\n' +
        '              </span>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>投資時機點：新創已經具有規模，需要更多資金進行產線擴充、人力擴編時，這時的新創要步入穩定成長期</li>\n' +
        '                <li>介入程度：較天使投資人高，以合作夥伴的角色協助營運，要求的投資回報也較高</li>\n' +
        '              </ul>\n' +
        '              <p>\n' +
        '                政府也有提相當多的天使投資及創業投資媒合資源，像是國發基金創業天使計畫、科技部TITAN計畫、經濟部中小企業處 Findit平台等，提供想找資金的新創們!\n' +
        '              </p>\n' +
        '              <span>更多政府資源網站：</span>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>\n' +
        '                  國發基金:<a href="https://pse.is/T4NXS" title="另開新視窗" target="_blank" rel="noopener noreferrer">創業天使投資方案</a>\n' +
        '                </li>\n' +
        '                <li>\n' +
        '                  科技部:<a href="https://pse.is/TA4ZL" title="另開新視窗" target="_blank" rel="noopener noreferrer">TITAN計畫</a>\n' +
        '                </li>\n' +
        '                <li>\n' +
        '                  經濟部中小企業處:<a href="https://pse.is/T5W94" title="另開新視窗" target="_blank" rel="noopener noreferrer">FINDIT平台</a>\n' +
        '                </li>\n' +
        '                <li>\n' +
        '                  經濟部工業局:<a href="https://pse.is/SF4F9" title="另開新視窗" target="_blank" rel="noopener noreferrer">加強投資策略性服務業推動計畫</a>\n' +
        '                </li>\n' +
        '                <li>\n' +
        '                  <a href="https://pse.is/MAFTR" title="另開新視窗" target="_blank" rel="noopener noreferrer">創櫃板</a>\n' +
        '                </li>\n' +
        '                <li>\n' +
        '                  <a href="https://sme.moeasmea.gov.tw/startup/modules/rmap/space_detail.php?sId=385" title="另開新視窗" target="_blank"\n' +
        '                    rel="noopener noreferrer">台灣孵化器</a>\n' +
        '                </li>\n' +
        '              </ul>'},
    { id: 18, title: '有哪些政府補助適合我們這種創新餐飲的創業家申請呢？', content: '<p>\n' +
        '                若你的產品特色或服務模式具備創新研發的特點，就可以考慮申請經濟部小型企業創新研發計畫 (SBIR)，通過審查可以獲得計畫總經費最多50%補助金。創新型服務業也可考慮申請服務業創新研發計畫(SIIR)，只要計畫具有可行性，並經過三個月的試營運，達成預期效益，就可以提出申請，補助上限最高200萬。\n' +
        '              </p>'},
    { id: 19, title: '有什麼狀況可能無法核貸就保失業者創業貸款？', content: '<ul style="list-style-type: decimal;">\n' +
        '                <li>經向票據交換所查詢其所使用之票據於受拒絕往來處分中，或知悉其退票尚未清償註記之張數已達應受拒絕往來處分之標準。</li>\n' +
        '                <li>經向財團法人金融聯合徵信中心查詢或徵授信過程中知悉，其有債務本金逾期未清償、未依約定分期攤還超過1個月、應繳利息未繳付而延滯期間達3個月以上或有信用卡消費款項逾期未繳納，遭發卡銀行強制停卡，且授信時仍未繳清延滯款項。</li>\n' +
        '                <li>曾依勞動部微型企業創業貸款、創業鳳凰婦女小額貸款或微型創業鳳凰貸款規定獲貸者。但已清償者，不在此限。</li>\n' +
        '              </ul>'},
    { id: 20, title: '請問經濟部中央型SBIR與地方型SBIR可以一起申請嗎？', content: '<p>\n' +
        '                可以，但需注意以下幾點：<br>\n' +
        '                (1) 須以不同計畫名稱申請。<br>\n' +
        '                (2) 計畫須設定不同核心技術驗收標的。<br>\n' +
        '                (3) 同一研發人員跨計畫人月數合計不可超過12人月。<br>\n' +
        '                (4) 同一研發設備跨計畫投入月數合計不可超過12個月。(各科目費用不得重覆編列。)\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                中央型SBIR與地方型SBIR的差異：<br>\n' +
        '                (1) 補助款上限不同：中央型SBIR依申請階段各Phase 1、Phase 2、Phase 2+有不同的補助上限，地方型SBIR最高補助額上限為100萬元至300萬不等。<br>\n' +
        '                (2) 營利事業登記限制不同：中央型SBIR申請以國內廠商為主，地方型SBIR申請廠商僅限地方縣市。<br>\n' +
        '                (3) 提案難易度不同：中央型SBIR採個案獨立審查，地方型SBIR為梯次審查。<br>\n' +
        '                (4) 收件時間不同：中央型SBIR採隨送隨審，地方型SBIR則依梯次收件，原則上一年一次。\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                對此項計畫若有疑問請洽詢SBIR聯絡窗口：<br>\n' +
        '                經濟部中小企業處SBIR計畫專案辦公室：0800-888-968。<br>\n' +
        '                經濟部中小企業處SBIR計畫網站：<a href="http://www.sbir.org.tw/" title="另開新視窗" target="_blank" rel="noopener noreferrer">http://www.sbir.org.tw/</a>。\n' +
        '              </p>'},
    { id: 21, title: '獲貸就保失業者創業貸款後有什麼情形會停止利息補貼？', content: '<ul style="list-style-type: decimal;">\n' +
        '                <li>所營事業有停業、歇業情形。</li>\n' +
        '                <li>所營事業變更負責人。</li>\n' +
        '                <li>積欠本息連續達6個月（但已清償積欠本息且恢復正常繳款者，勞動部得繼續補貼）。</li>\n' +
        '              </ul>'},
    { id: 22, title: '我之前曾在職訓局上過創業課程，是否符合申請青年創業及啟動金貸款規定之課程時數呢？', content: '<p>\n' +
        '                如為3年以內所參加之職訓局課程(屬政府單位所辦理)且課程內容涵括創業適性評估、企業管理、行銷管理、品牌管理、財務管理、法務管理、資訊管理、創業計畫書撰寫、工商登記、稅務管理或電子商務等，則可作為參與創業課程之認證。若為技能培訓之課程內容則需另外完成經營管理課程20小時，中小企業處有提供數位課程及實體課程資源可運用，可於中小企業網路大學校報名課程或洽服務專線0800-056-476\n' +
        '              </p>'},
    { id: 23, title: '申請青年創業及啟動金貸款必備之創業課程，哪裡有開課資訊？', content: '<p>\n' +
        '                申請青年創業及啟動金貸款，三年內需受過政府或其認可之單位開辦創業輔導相關課程二十小時，或取得大專院校創業課程二學分證明，目前提供數位課程及實體課程資源可運用，實體課程可於「中小企業網路大學校→終身學習護照→查詢實體課程(可按課程費用條件搜尋)」查詢或洽服務專線0800-056-476 \n' +
        '                <br>\n' +
        '                中小企業網路大學校-數位課程<a href="http://www.smelearning.org.tw/" title="另開新視窗" target="_blank" rel="noopener noreferrer">http://www.smelearning.org.tw/</a><br>\n' +
        '                中小企業網路大學校-實體課程<a href="http://www.smelearning.org.tw/" title="另開新視窗" target="_blank" rel="noopener noreferrer">http://www.smelearning.org.tw/</a><br>\n' +
        '                女性創業飛雁計畫-實體課程<a href="http://woman.sysme.org.tw/" title="另開新視窗" target="_blank" rel="noopener noreferrer">http://woman.sysme.org.tw/</a>\n' +
        '              </p>'},
    { id: 24, title: '我想要籌措更多資金，協助商品量產，除了貸款之外還有什麼獲得資金的相關管道？', content: '<p>\n' +
        '                現在臺灣群眾募資的方式已經日漸成熟，不同於貸款要返還本金及利息，如果你的產品有具有募資潛力，可以透國內外的募資平台，像是美國的Kickstarter和indiegogo、日本的Makuake等等，還有台灣知名的 嘖嘖zeczec、FlyingV ，展示自己的產品，同時吸引投資人的注意獲取資金。另外政府也有相關的募資計畫，像是創業天使計畫、創櫃板等籌資方式，都是可行的管道喔！\n' +
        '              </p>'},
    { id: 25, title: '如果遭遇重大天災，導致事業有損失，政府有提供哪些協助？', content: '<p>\n' +
        '                受災企業可申請銀行債權債務協商及寬延退票處理，有需求之企業請向馬上辦服務中心提出申請。另也透過「在地關懷主動服務中小企業專案」針對受災情況較為嚴重地區成立「主動服務團」，其成員除當地中小企業團體外，尚包括經營管理與財稅會計專家，相關服務團隊將深入災區以瞭解、關心並協助中小企業解決租稅減免、復舊計畫、融資貸款等問題，有意申請者可電洽該地區縣市政府中小企業服務中心或撥在地關懷免付費專線0800-580185洽詢。\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                並提醒受災企業，應儘量蒐集完整受災證明，包括拍照存證，以利日後申貸及相關租稅減免之需要，詳情請至財政部賦稅署網站（<a href="http://www.dot.gov.tw/" title="另開新視窗" target="_blank" rel="noopener noreferrer">http://www.dot.gov.tw/</a>）或電(02)2322-8000查詢相關租稅減免注意事項。\n' +
        '              </p>'},
    { id: 26, title: '企業有債務（或信用瑕疵），資金或還款上遇到困難，需要與銀行協商，要怎麼辦？', content: '<p>\n' +
        '                若有繼續經營且提出償債計畫，於金融機構之授信屆期，經向原承貸金融機構申請展延或分期攤還，而未達成合意之符合中小企業認定標準規定之中小企業，且未曾依「消費者債務清理條例」、「破產法」或其他法規，進行債務協商、更生、清算、和解、重整或破產程序者，應檢附下列資料向經濟部提出申請：\n' +
        '              </p>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>申請表(依受理機關規定格式)</li>\n' +
        '                <li>聲明書</li>\n' +
        '                <li>最近三年度財務報表影本各一份</li>\n' +
        '                <li>營運及償債計畫書。洽詢專線:0800-056-476《中小企業營運與融資協處計畫》。</li>\n' +
        '              </ul>\n' +
        '              <p>\n' +
        '                註：企業規模超過中小企業認定標準之非中小企業者，受理申請機關為經濟部部工業局0800-000-257。\n' +
        '              </p>'},
    { id: 27, title: '我有一個新技術/新產品，在研發上遇到資金短缺，請問有何補助可申請？', content: '<p>\n' +
        '                目前經濟部主要提供以下補助計畫，可供中小企業主申請運用：<br>\n' +
        '                (1) 小型企業創新研發計畫(SBIR)：<br>\n' +
        '                鼓勵國內中小企業加強創新技術或產品的研發，針對符合中小企業標準之合法設立企業，依據「經濟部促進企業開發產業技術辦法」所訂定的計畫提供補助。申請資格及方式請查閱計畫網站\n' +
        '                <a href="http://www.sbir.org.tw" title="另開新視窗" target="_blank" rel="noopener noreferrer">http://www.sbir.org.tw</a>，對此項計畫若有疑問請致電服務專線：0800-888-968。\n' +
        '                <br>\n' +
        '                (2) 學界推動在地產業科技加值創新計畫(原學界協助中小企業科技關懷計畫)：<br>\n' +
        '                為促進產學合作，協助傳統產業升級，藉由學界豐沛研發能量協助並鼓勵產業投入研發及爭取政府研發補助資源，計畫詳細資訊請參考計畫網站：<a href="https://sita.mirdc.org.tw/login" title="另開新視窗" target="_blank" rel="noopener noreferrer">https://sita.mirdc.org.tw/login</a>\n' +
        '                或洽北區(含離島)-02-23918755分機118、中區-04-23502169分機705、南區-07-3517161分機6221。\n' +
        '                <br>\n' +
        '                (3) 協助傳統產業技術開發計畫(CITD)：<br>\n' +
        '                為解決傳統產業所面臨之困境，經濟部推動「協助傳統產業技術開發計畫」，透過提供傳統產業研發補助資金，鼓勵業者自主研發，厚植傳統產業之創新研發能力、加速升級轉型及提升競爭力。\n' +
        '                詳細資訊請洽02-2709-0638<br>\n' +
        '                <a href="https://www.citd.moeaidb.gov.tw/CITDWeb/Web/Index.aspx" title="另開新視窗" target="_blank" rel="noopener noreferrer">https://www.citd.moeaidb.gov.tw/CITDWeb/Web/Index.aspx</a>\n' +
        '                <br>\n' +
        '                (4) 服務業創新研發計畫(SIIR)<br>\n' +
        '                鼓勵業者進行開發工作為目的，補助業者開發資金為方法，引導投入服務業新服務商品、新經營模式、新行銷模式或新商業應用技術之創新開發工作，申請資訊請參考計畫網站<a href="http://gcis.nat.gov.tw/neo-s/" title="另開新視窗" target="_blank" rel="noopener noreferrer">http://gcis.nat.gov.tw/neo-s/</a>或洽(02)2701-1769分機231~240\n' +
        '              </p>'},
    { id: 28, title: '我目前失業，有沒有貸款可以協助創業？', content: '<p>\n' +
        '                為協助失業之被保險人創業，增加創業成功機會，降低創業風險，提昇勞動力參與率。勞動部提供就保失業者創業貸款，若為就業保險被保險人失業而有意自行創業者，並具備下列資格者可提出申請：\n' +
        '                <br>\n' +
        '                一、接受勞動部創業諮詢輔導及適性分析。\n' +
        '                <br>\n' +
        '                二、3年內參加本部或政府機關(構)創業研習課程至少18小時。\n' +
        '                <br>\n' +
        '                三、登記為所營事業之負責人，且登記日前14日內無就業保險投保紀錄及未擔任其他事業負責人。\n' +
        '                <br>\n' +
        '                詳情請洽勞動部諮詢專線0800-092957，<a href="https://beboss.wda.gov.tw/cht/index.php?code=list&ids=32" title="另開新視窗" target="_blank"\n' +
        '                  rel="noopener noreferrer">https://beboss.wda.gov.tw/cht/index.php?code=list&ids=32</a>\n' +
        '              </p>'},
    { id: 29, title: '我是農民，有沒有什麼貸款可以申請？', content: '<p>\n' +
        '                若您計劃投入農業經營者（農業相關科系畢業，或為農委會及其所屬機關所開辦農業訓練之學員），以及已從事農業經營而規畫擴大經營規模之農民朋友們，可向設有信用部之農(漁)會申請一般農業貸款外，農委會另有提供青年從農創業貸款可申請，相關資訊請參考「青年農民輔導平台→從農資源→資金資源」，或可洽農委會-農業金融局0800-388-599。\n' +
        '                <br>\n' +
        '                青年農民輔導平台(<a href="https://academy.coa.gov.tw/YF/" title="另開新視窗" target="_blank" rel="noopener noreferrer">https://academy.coa.gov.tw/YF/</a>)。\n' +
        '              </p>'},
    { id: 30, title: '我是更生人，有合適的創業貸款可以申請嗎？', content: '<p>\n' +
        '                更生朋友經評估有就業需求，且具有創業相關技能或工作經歷而缺乏資金，目前財團法人臺灣更生保護會（<a href="http://www.after-care.org.tw/" title="另開新視窗" target="_blank" rel="noopener noreferrer">http://www.after-care.org.tw/</a>）有提供3種創業貸款：創業小額貸款、更生事業貸款及甘霖更生事業貸款，欲創業之更生朋友，需先至各分會面談，由專任人員提供相關的申請流程說明及書表填寫，更生保護會並協助分析所創事業的可行性、規劃創業的步驟，提供正確的經營觀念。詳細請洽臺灣更生保護會專線：0800-788595。\n' +
        '              </p>'},
    { id: 31, title: '我有領身心障礙證明，想創業沒有資金怎麼辦？', content: '<p>\n' +
        '                直轄市、各縣市政府社政單位(如勞工局、社會處等)，均設有身心障礙者創業貸款或貸款利息補貼等辦法，但各縣市申請方式、申辦規定皆不同，建議您致電衛生福利部 1957福利諮詢專線。\n' +
        '              </p>'},
    { id: 32, title: '我是原住民，有什麼創業貸款可以申請？', content: '<p>\n' +
        '                原住民委員會 (<a href="http://www.apc.gov.tw/" title="另開新視窗" target="_blank" rel="noopener noreferrer">http://www.apc.gov.tw/</a>)之原住民族綜合發展基金貸款有提供原住民經濟產業貸款、青年創業貸款及原住民族綜合發展基金貸款-經濟產業貸款，若有疑問請您就近洽所屬直轄市、縣（市）政府或鄉（鎮、市、區）公所詢問，亦可撥打免付費原住民金融服務專線：0800-508-188。\n' +
        '              </p>'},
    { id: 33, title: '我是家暴受害/特殊境遇家庭創業者，請問有貸款補助嗎？', content: '<p>\n' +
        '                勞動部為加強照顧及扶助特殊境遇家庭、家庭暴力被害人創業，有提供創業貸款利息補貼，凡申請青年創業及啟動金貸款或微型創業鳳凰貸款，檢具直轄市、縣（市）政府所屬社政單位開立之特殊境遇家庭身分證明文件或家庭暴力被害人身分證明文件經同意獲貸者，申貸者前3年不需負擔利息，第4年起負擔年息1.5%，補貼期限最長7年，利息補貼以新台幣100萬元貸款額度為限，詳情請洽： 0800-092-957。\n' +
        '              </p>'},
    { id: 34, title: '我的自有資金不足，如何能募集眾人的資金？創業資金不足時，除青創啟動金貸款外，還有哪些管道？', content: '<p>\n' +
        '                創業募資的管道很多，除了創業貸款之外，目前還可利用群眾募資的方式進行。透過櫃買中心的創意集資資訊揭露專區，與募資平台合作，提出可行之business\n' +
        '                model，吸引到投資人出資。另外還有創櫃板、創業天使、創投等籌資方式可以運用。不同於貸款要返還本金及利息，透過群眾募資平台，只要將產品商品化，回饋給投資人即可，這些管道也是可選擇的。\n' +
        '                <br>\n' +
        '                此外，經濟部中小企業處「財務融通輔導資源手冊」，也針對青年創業、創新發展、研發或升級轉型貸款等融資提供數種方案選擇。比方說「青年創業及啟動金貸款」，整合創業啟動金與原本的青創貸款，除提供優惠貸款利率，同時也提供完善的協助措施，如創業輔導顧問等，讓銀行在放款方面，除衡量借款人的條件及其擔保品等外，也能看到借款人的事業發展前景，有利放款。\n' +
        '                <br>\n' +
        '                若有任何相關創業疑問，經濟部中小企業處設有免費服務電話─馬上辦服務中心0800-056-476，可為大眾第一時間諮詢創業問題或轉接專業的輔導團隊提供協助。\n' +
        '              </p>'},
    { id: 35, title: '請問我的事業之工商登記已超過5年，也不符合青年創業及啟動金、微創鳳凰等貸款的申請資格，還有哪些貸款可以申辦？', content: '<p>\n' +
        '                若您事業體成立已超過5年，且不符合青年創業及啟動金、微創鳳凰等任一創業貸款申貸資格（詳細資格請上新創圓夢網→找資金查詢），可致電經濟部中小企業處馬上辦服務中心，洽詢專線：0800-056-476，服務內容主要為受理中小企業相關申訴及求助之案件；提供中小企業各類諮詢服務，包含政府對中小企業有關財務融通、經營管理、創業育成及互助合作等相關輔導資訊，並作為政府與中小企業間、銀行與中小企業間重要的溝通橋樑。\n' +
        '              </p>'},
    { id: 36, title: '我想進口一些3C產品在網拍銷售，需要辦理出進口廠商登記嗎？', content: '<p>\n' +
        '                隨著近年電商潮流及第三方支付的興起，使國際間貨物的交流更為頻繁，要成為出進口廠商也不再像過往有許多的條件限制。若每月營業額未達8萬元或僅有稅籍登記的小型網拍賣家，可透過個人名義辦理進出口，但建議通行的貨物須為少量、小額，才能降低風險；若已有登記公司行號，則準備好英文名稱及相關英文版基本資料，即可登記成為出進口廠商。\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                至於登記的流程也可以線上申辦囉，這邊就來向大家說明申請的須知：\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                申請資格：公司、商號申請登記為出進口廠商前，應先向經濟部國際貿易局申請英文名稱預查；預查之英文名稱經核准者，保留期間為6個月。取得執照後才能進行廠商登記。\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                申請方式：以一般帳號或工商憑證線上申請；無申請費用。\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                申辦流程：\n' +
        '              </p>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>至出進口廠商管理系統(<a href="https://fbfh.trade.gov.tw/fb/web/homef.do" title="另開新視窗" target="_blank" rel="noopener noreferrer">https://fbfh.trade.gov.tw/fb/web/homef.do</a>)，以一般帳號或工商憑證登入進行線上申請。 </li>\n' +
        '                <li>登打廠商登記資料後，傳送資料。</li>\n' +
        '                <li>審核結果由系統發送電子郵件通知，申請人亦可至出進口廠商管理系統查詢審核結果。 </li>\n' +
        '              </ul><br>\n' +
        '              <p>\n' +
        '                另外要注意的是，依照貿易法第9條第3項規定，登記後若申請撤銷或廢止出進口廠商登記，自撤銷或廢止日起，二年內不得重新申請。因此若屆時有撤銷或廢止需求，也要先評估清楚喔！ \n' +
        '              </p>\n' +
        '              <p>\n' +
        '                經濟部國際貿易局聯絡窗口：<a href="https://taiwangov.com/a80ts" title="另開新視窗" target="_blank" rel="noopener noreferrer">https://taiwangov.com/a80ts</a>\n' +
        '                <br><br>\n' +
        '                若經營上遇到進出口物流、報關或金流等相關問題，也歡迎可洽創業諮詢服務專線 0800-589168 預約專業顧問幫您評估或提供建議！\n' +
        '                <br><br>\n' +
        '                小提醒：網拍賣家進口商品銷售前也要留意有些東西須經過檢驗才能販售，可先到經濟部標檢局網站查詢，多一分確認，多一分保障！<a href="https://www.facebook.com/moea.gov.tw/posts/1935101186530048" title="另開新視窗" target="_blank" rel="noopener noreferrer">https://www.facebook.com/moea.gov.tw/posts/1935101186530048</a>\n' +
        '              </p>'},
    { id: 37, title: '該怎麼評估我需不需要自己成立公司行號呢？', content: '<p>\n' +
        '                現在自由創業風氣盛行，尤其有一技之長的話更常見於正職工作外接案賺外快，不過若接案的收入達到一定標準，就要好好考慮登記公司行號的必要性囉！這邊就來跟大家分享幾個可以考量的切入點：\n' +
        '              </p>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>\n' +
        '                  每月銷售額：依國稅局規定，透過網路銷售貨物或勞務，且每月銷售額已達到8萬元(銷售貨物)或4萬元(銷售勞務)者，應盡速向國稅局辦理稅籍登記。\n' +
        '                </li>\n' +
        '                <li>客戶或營業需求：</li>\n' +
        '                (1)\t客戶在意交易對象要有個企業主體，而不是個人<br>\n' +
        '                (2)\t客戶有報稅需求需要開立發票<br>\n' +
        '                (3)\t希望發展品牌、建立形象<br>\n' +
        '                (4)\t有融資需求，希望以企業名義提出<br>\n' +
        '                (5)\t要從國外代理商品，需要有企業主體讓國外廠商可查詢等\n' +
        '              </ul><br>\n' +
        '              <p>\n' +
        '                當然設立公司行號後，相對也須面對一些成本的增加，例如：行政規費、稅務申報、辦公室費用或人事成本等；不過設立後就是合法經營，也更能建立管理的制度，因此是否需要設立公司行號或是該設立哪種組織，還是要依照每個創業者的營業需求與規模去做判斷喔！ \n' +
        '              </p>\n' +
        '              <p>\n' +
        '                若實在不確定自己是否該辦理工商登記，或是在公司與行號之間不知如何抉擇，歡迎您可撥打創業諮詢服務專線0800-589168，預約專業顧問幫您評估！\n' +
        '              </p>'},
    { id: 38, title: '我想先登記一家小型的商行，該準備哪些文件去辦理呢？', content: '<p>\n' +
        '                申請商業設立登記，依照【商業登記申請辦法】第五條規定，應檢具下列文件：\n' +
        '              </p>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>申請書。</li>\n' +
        '                <li>負責人的身分證明文件；若預計和友人合夥創業，需提供合夥人的身分證明文件，並附上你們的合夥契約書。 </li>\n' +
        '                <li>資本額證明文件。</li>\n' +
        '                *資本額未達新臺幣25萬元者，免附證明文件。\n' +
        '                <br>\n' +
        '                *資本額達新臺幣25萬元(含)以上者，金額應存入以商業名義開立之帳戶，商業未核准登記前可以籌備處名義開戶，存入後再檢附存摺或銀行對帳單、查詢單等。\n' +
        '                <br>\n' +
        '                <li>所在地之建物所有權狀，若負責人或合夥人不是建物所有權人，應附具所有權人同意書。</li>\n' +
        '                *建物所有權狀可用建物謄本、房屋稅籍證明、最近一期房屋稅單或其他可證明建物所有權人之文件替代。\n' +
        '                <br>\n' +
        '                *所有權人同意書則可用商業與所有權人簽訂之租賃契約，或載明可辦理商業登記或供營業使用之負責人與所有權人簽訂租賃契約替代。\n' +
        '              </ul><br>\n' +
        '              <p>\n' +
        '                備妥商業登記應備文件，可透過臨櫃、郵寄各縣市政府工商科，或經由經濟部一站式網站、超商ibon機臺，皆可送件申辦商業登記。\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                取得商業登記核准函後，再洽登記所在地之財政部國稅局所屬稽徵所(分局)辦理營業登記，全部登記好便完成手續囉！\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                公司與商業及有限合夥一站式線上申請作業：<a href="https://pse.is/3facq6" title="另開新視窗" target="_blank"\n' +
        '                  rel="noopener noreferrer">https://pse.is/3facq6</a>\n' +
        '              </p>'},
    { id: 39, title: '我打算自己成立公司進行軟體開發？怎麼知道哪種公司型態比較適合我？', content: '<p>\n' +
        '                在臺灣常見的公司型態包含有限公司、股份有限公司、閉鎖性股份有限公司，這三種公司型態各有其優缺點，一定要充分了解之後再選擇適合自己事業的公司型態！\n' +
        '              </p>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>有限公司：由1人以上股東所組成，股東組成人數較簡單，但是當要進行股份轉讓、變更公司章程等決議都須經過其他股東同意，股東意見分歧時容易造成公司決策延宕。</li>\n' +
        '                <li>股份有限公司：應有2人以上自然人股東或1人以上法人或政府股東，公司成立一年後，股份原則上就可以自由轉讓，公司重要事項表決為股東多數決，未來公司若有募資需求，成立股份有限公司會是比較好的選擇喔！</li>\n' +
        '                <li>閉鎖性股份公司：可以透過技術出資保障資金較少的股東，也能夠設立章程限制股份轉讓，但對創投或投資人來說，無法自由轉讓股份容易降低投資的意願。</li>\n' +
        '              </ul>'},
    { id: 40, title: '請問完成工商登記後，必須加入各地區的同業公會嗎？', content: '<p>\n' +
        '                是，需加入公會，根據內政部商業團體法規範：\n' +
        '              </p>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>商業團體採取強制入會規定的意義，乃在集合同業力量，保障同業的利益，促進同業的發展，並建立同業的自律及自治秩序，經由商業團體的自律與評鑑制度，淘汰違法廠商，獎勵優良廠商，俾發揮團體的「經濟性功能」及「社會性功能」。如上所述，商業團體係透過集合同業力量，爭取及保障同業的利益，如不規定同業強制入會，不加入同業公會者仍可同享公會爭取之利益，形同搭便車，對已加入同業公會者是一種不公平，恐造成權利義務不對等，爰在公共利益及公共秩序之考量下，「業必歸會」政策確有其必要性，惟為兼顧社會實際需求，內政部在現行輔導商業團體之法制上，已採取柔性強制手段，逐步放寬「業必歸會」政策中之「強制入會」規定，例如：現行商業團體法第12條已放寬兼具經營二種以上業務範圍之商業者，除其他法律另有規定者外，至少應選擇一業加入該公會之規定。</li>\n' +
        '                <li>又為落實強制入會政策，對不依法加入公會為會員之公司、行號採取相關罰則規定有其必要性。</li>\n' +
        '              </ul>\n' +
        '              *公會入會規範及相關細節請逕洽各縣市政府社會局/處之人民團體科。'},
    { id: 41, title: '閉鎖性公司與一般股份有限公司有何不同呢？', content: '<p>\n' +
        '                閉鎖性股份有限公司<br>\n' +
        '                股東人數：不超過50人<br>\n' +
        '                出資種類：現金、公司事業所需之財產、技術、勞務(但勞務不得超過發行總數一定比例)<br>\n' +
        '                股權轉讓：章程得載明限制股東股份轉讓<br>\n' +
        '                股東會：得視訊會議或書面行使表決權而不實際集會<br>\n' +
        '                股東表決權：每股一表決權，但得發行複數表決權及特定事項表決權特別股<br>\n' +
        '                盈餘分配：於每季或每半會計年度分配一次<br>\n' +
        '                變更組織：三分之二以上股東同意變更為非閉鎖型股份有限公司\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                股份有限公司 (非閉鎖性)<br>\n' +
        '                股東人數：2個以上自然人股東或政府、法人股東1人<br>\n' +
        '                出資種類：現金、其他資產、技術<br>\n' +
        '                股權轉讓：股份自由轉受讓<br>\n' +
        '                股東會：得視訊會議或書面行駛表決權而不實際集會<br>\n' +
        '                股東表決權：每股一表決權，但特別股得限制無表決權<br>\n' +
        '                盈餘分配：於每季或每半會計年度分配一次<br>\n' +
        '                變更組織：全體股東同意變更為閉鎖型股份有限公司\n' +
        '              </p>'},
    { id: 42, title: '攤販需否辦理商業登記？', content: '<p>\n' +
        '                依商業登記法第5條規定\n' +
        '                下列各款小規模商業，得免依本法申請登記：\n' +
        '              </p>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>攤販。</li>\n' +
        '                <li>家庭農、林、漁、牧業者。</li>\n' +
        '                <li>家庭手工業者。</li>\n' +
        '                <li>民宿經營者。</li>\n' +
        '                <li>每月銷售額未達營業稅起徵點者。</li>\n' +
        '              </ul>\n' +
        '              因此，攤販得免辦理商業登記，至於是否需申請攤販營業許可證，請逕洽各縣市政府。'},
    { id: 43, title: '開設網路商店或在網路上販售物品，要如何辦理商業登記？', content: '<p>\n' +
        '                所謂網路商店是指透過網路虛擬通路販賣商品，而網路行銷、直銷或店鋪貨架銷售方式為行銷通路手段。如果原本已經以店面方式經營零售類業務，並透過網路行銷方式販賣商品，申請商業登記則與一般商店無異，其營業項目則須視販賣何種商品而定。\n' +
        '                <br>\n' +
        '                如以無店面非傳統零售方式，運用郵件及廣播、電視、網際網路等電子媒介方式經營零售商品之態樣，則應登記「F399040無店面零售業」；另依商業登記法第9條之立法意旨，商業登記所營業務可以「除許可業務外，得經營法令非禁止或限制之業務」之概括方式記載。\n' +
        '              </p>'},
    { id: 44, title: '獨資或合夥之商業可否變更為公司組織？', content: '<p>\n' +
        '                公司係依公司法組織設立之法人，而獨資、合夥之商業則係依商業登記法組織設立之事業，二者設立之法律依據不同，故為不同之權利義務主體，自然沒有獨資或合夥之商業變更組織為公司組織之情事。\n' +
        '              </p>'},
    { id: 45, title: '資本額應該設立要多少？資本額的金額高低，會影響到申請貸款嗎？', content: '<p>\n' +
        '                公司資本額應設立多少？有無最低限制？資本額的金額高低，會影響到未來申請貸款嗎？這些都是創業新手，容易會遇到的問題。那本篇文章將為您解惑，讓您看完文章對公司登記資本額，有更深入的了解喔！\n' +
        '              </p>\n' +
        '              <p>\n' +
        '                開公司資本額的金額＆條件，最低金額為多少？\n' +
        '                <br>\n' +
        '                <br>\n' +
        '                ◆ 公司資本額是什麼呢？<br>\n' +
        '                簡單來說，就是你創業時，預計投入多少的資金，來作為公司初期的營運資產，可用來支付例如辦公室租金、水電費、生產設備等成本費用，幫助你的事業正常營運的第一桶金。\n' +
        '                <br>\n' +
        '                <br>\n' +
        '                ◆ 資本額有規定最低要設立多少金額嗎？<br>\n' +
        '                現在的法令已廢除最低資本額限制，理論上只有1元也可以設立公司，不過實務上作為創業的第一桶金，1元連設立公司的直接費用都無法支付，也就是說無法負荷公司的營運(*註1)，會無法通過會計師驗資而無法成立公司！\n' +
        '                <br>*註1：依公司法第7條規定，資本額仍應先經會計師查核簽證，認定足敷設立成本，登記機關才會核淮登記。\n' +
        '                <br>\n' +
        '                <br>\n' +
        '                創業資本金額的多寡，會造成影響嗎？<br>\n' +
        '                資本額的數字大小除了代表公司的營運資金外，對於一家公司來說，也具有公司規模大小、償債履約的能力等等意義，因此若資本額設定的太低，後續會有3大缺點：\n' +
        '                <br><br>\n' +
        '                ◆ 申請融資貸款、政府補助或標案會受限<br>\n' +
        '                從銀行的角度來看，公司實收資本額也是評估是否貸款與貸款金額的一環，如果是新設立的事業，即使前景看好，資本額與借貸金額差異過大，容易被拒貸或獲貸金額減半；而申請政府相關政策貸款或補助，若無特殊情形，通常會以申請者事業體的資本額為放款或補助金額的上限，會較難申請到創業計畫所需的金額。\n' +
        '                <br><br>\n' +
        '                以青年創業及啟動金貸款來說，通常第一次申貸額度不會超過設立資本額，但銀行會透過實地審查去了解企業主實際需要。有些標案，在選擇廠商時，廠商的資本額也是標案的資格條件之一。\n' +
        '                <br><br>\n' +
        '                ◆ 不容易取得客戶、廠商的信任<br>\n' +
        '                在生意業務往來上，對往來的廠商客戶來說，公司資本額愈高，公司的履約能力就愈高。舉例來說：A公司想和B公司採購100萬的貨品，A和B公司是第一次合作，B公司發現A公司的資本額只有10萬元，此時B公司就可能會擔憂A公司無法支付貨款，因而要求A公司需先付清貨款才會出貨，或者拒絕交易。也就是說，資本額的高低容易影響到公司對外的印象。\n' +
        '                <br><br>\n' +
        '                ◆ 增資的時間與金錢成本<br>\n' +
        '                若一開始的資本額過低，預計再進行增資的話，必須再跑一次會計師簽證、變更登記等等的流程，增加相關的花費與時間成本。此外，如果在增資的過程中，現有的資金已支付貨款或卡在存貨上，結果手上沒有多餘的資金可存入，也可能導致無法順利增資喔！\n' +
        '                <br><br>\n' +
        '                ※請注意，千萬不能借資登記(虛增資本額)，這是違法的行為喔!\n' +
        '                不能用自己的信用貸款、或和別人借錢來充作公司資本額，並在會計師進行資本額簽證後再從公司帳戶領出歸還，這個負責人將資金抽走的行為，觸犯了公司法第九條：應收股款股東未實際繳納罪、中華民國刑法第二百一十四條：使公務員登載不實罪、商業會計法第七十一條：偽造帳冊及財務報表罪。\n' +
        '                <br><br>\n' +
        '                資本額只能放在銀行嗎？可以動用嗎？\n' +
        '                <br><br>\n' +
        '                資本額通常須放在銀行2天後才可動用。<br>\n' +
        '                第一天：「到銀行開戶、存入資本額」<br>\n' +
        '                第二天：進行「存款餘額證明的申請」<br>\n' +
        '                （少部分銀行有另外的規定，請參考各家銀行說明。）<br>\n' +
        '                ※請特別留意，請勿在會計師驗資後，就全額領出，如沒有正當用途及佐證，將有可能被認為是虛增資本額而觸法唷！\n' +
        '                <br><br>\n' +
        '                本文感謝創業顧問：靳知勇會計師監修\n' +
        '              </p>'},
    { id: 46, title: '開公司要多少錢？開公司的資本額、公司登記及設立流程一次看！', content: '<p>\n' +
        '                如何創業成立公司？需要多少資本額？資本額有無最低限制？這是許多創業新手會想提出的疑問。本篇文章將統整出三項關於開公司與資本額的重點來為大家解惑，讓您看完對公司登記資本額有更深入的了解！\n' +
        '                <br><br>\n' +
        '                開公司要多少錢？\n' +
        '                <br><br>\n' +
        '                以公司設立登記為例，預查名稱的費用是300元，若是資本額在400萬元以內，政府規費是新台幣1,000元，另外還會有會計師簽證費，如有委託事務所處理，還會有代辦服務費等等。\n' +
        '              </p>\n' +
        '              <ul style="list-style-type: disc;">\n' +
        '                <li>預查審查費：300元，但以網路傳輸方式申請者，應繳納預查審查費150元。</li>\n' +
        '                <li>公司設立登記費：實收資本總額每新臺幣4,000元以1元計算；未達新臺幣1,000元者，以新臺幣1,000元計收。以網路方式申請者，應繳納之登記費減收新臺幣300元。</li>\n' +
        '                <li>商業設立登記費用：新臺幣1,000元，但以網路傳輸方式申請者，應繳納之登記費減徵二成</li>\n' +
        '                <li>有限合夥登記所定出資額：每新臺幣4,000元以新臺幣1元計算；登記費未達新臺幣1,000元者，以新臺幣1,000元計收。以網路方式申請者，應繳納之登記費減收新臺幣300元。</li>\n' +
        '              </ul>\n' +
        '              <br>\n' +
        '              <p>\n' +
        '                ※ 有下列情事之一者，免繳規費：<br>\n' +
        '                (1)\t因行政區域調整或門牌改編致組織所在地、負責人或合夥人地址變動而申請變更登記。<br>\n' +
        '                (2)\t申請停業登記、延展登記、復業登記、解散登記或廢止登記。<br>\n' +
        '                (3)\t因行政區域之調整致發生商業名稱相同之商業，申請商業名稱變更登記。<br>\n' +
        '                (4)\t併案申請之其他登記。<br>\n' +
        '                (5)\t因法律變更而須申請登記。\n' +
        '                <br><br>\n' +
        '                資料來源：公司登記規費收費準則、商業登記規費收費準則、有限合夥規費收費準則\n' +
        '                <br><br>\n' +
        '                設立公司的資本額，有最低限制嗎？\n' +
        '              </p>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>目前法規現已廢除現行設立公司最低資本額限制，公司登記資本額規定，除許可法令特別規定外，公司申請設立時，最低資本額並不受限制。而在廢除設立公司的最低資本額限制後，政府同時也規定應將資本額列為登記事項，並公告在資訊網站上，以保護他人的交易公平與安全。</li>\n' +
        '                <li>公司申請設立時，依公司法第7條規定，其資本額仍應先經會計師查核簽證，認定足敷設立成本，登記機關才會核淮登記。</li>\n' +
        '                <li>依據商業登記申請辦法第5條第1項及第2項之規定：辦理商業登記需檢附資本額證明文件，而商業之資本額未達新臺幣25萬元者，免附證明文件。而證明文件如存款餘額證明書或存摺影本。</li>\n' +
        '                <li>有限合夥組織設立時，依有限合夥登記申請辦法第5條第4項，若出資額3000萬以上或合夥人數達35人以上，需會計師查核報告書（但出資額全部現金者除外）。</li>\n' +
        '              </ul><br>\n' +
        '              <p>\n' +
        '                設立公司的3大種類：\n' +
        '                <br><br>\n' +
        '                我國目前組織型態多元，常見的創業組織型態有：\n' +
        '              </p>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>商業(獨資合夥)組織：以營利為目的以獨資或合夥方式經營之事業，非經所在地主管機關登記不得成立。每個月的營業額若沒有到營業稅起徵點(銷售貨物8萬；銷售勞務4萬)，可以合法的免辦理登記。</li>\n' +
        '                <li>公司組織：依公司法規定，以營利為目的，依公司法組織、登記、成立之社團法人，經主管機關核准登記後，方得成立公司。公司種類主要包含：有限公司、股份有限公司等。</li>\n' +
        '                <li>有限合夥組織：為提供單純投資者與積極經營者共同從事經營活動之新選擇，賦予投資者與經營者不同的權責，建構一個重視個人特性，尊重契約自由及私法自治精神之商業組織。經主管機關核准登記後，方得以有限合夥名義經營。</li>\n' +
        '              </ul><br>\n' +
        '              <p>\n' +
        '                成立公司前的2點基本評估：\n' +
        '              </p>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>每個月實際的銷售額：無論是實體線下銷售，或是透過網路進行線上銷售，每個月銷售額若已達到營業稅起徵點(銷售貨物8；銷售勞務4萬)，都應考慮立刻辦理商業或公司登記，以符合法令規定。</li>\n' +
        '                <li>客戶或營業上是否有需求：<br>\n' +
        '                  若在業務開展的過程中，發現有以下的情形，以應該考慮辦理商業或公司登記。\n' +
        '                </li>\n' +
        '                (1)\t客戶在意交易對象要有統一編號。想在電商平台上架商品，就會被要求提供統一編號。<br>\n' +
        '                (2)\t客戶有取得統一發票的需要。特別是跟政府單位或上市櫃公司作生意，就會被要求請款時需要檢附統一發票。<br>\n' +
        '                (3)\t自己希望發展品牌、建立形象。<br>\n' +
        '                (4)\t自己有融資需求，例如「青年創業及啟動金貸款」的申辦，就要求申辦者有辦理商業或公司登記。<br>\n' +
        '                (5)\t自己想代理商品；無論代理國內或國外的商品，原廠都會需要確認在臺灣是有登記商業或公司的事實。<br>\n' +
        '                (6)\t自己想參與政府標案或大企業合作。\n' +
        '              </ul><br>\n' +
        '              <p>\n' +
        '                此外，登記設立後，還會產生其他成本，例如：行政規費、辦公室費用或人事成本等，在決定是否辦理登記前，也都應該考慮清處。不過一但設立後，我們就是合法經營，也能建立管理的制度，朝永續經營的方向邁進。\n' +
        '                <br><br>\n' +
        '                在決定要辦理登記後，登記商業、公司，還是有限合夥，也是重要的問題。不同組織型態的稅賦，及對債務承擔的責任，是有差異的，這些也都必須列入考量。\n' +
        '                <br>\n' +
        '                因此該設立哪種組織，還是要依照每個創業者的營業需求與規模去做判斷喔！\n' +
        '              </p>'},
    { id: 47, title: '公司登記和商業登記有什麼差別？', content: '<p>\n' +
        '                公司係指依公司法規定組織登記成立之社團法人，股東就其出資額負責（無限公司除外）；而商業登記法所稱之商業，也就是一般所指的商號（行號），是指以營利為目的獨資或合夥經營事業，負責人或合夥人負無限責任。\n' +
        '                在銷售貨物或勞務時，商業登記單位如每月營業額在20萬元以下，可向國稅局申請免用統一發票，享受報稅簡便之優惠，每季由國稅局按查定營業額乘以1%稅率計算營業稅，使用統一發票則徵收5%稅率；而公司需要使用統一發票，稅率5%。\n' +
        '              </p>'},
    { id: 48, title: '如何申辦工商登記？工商登記流程？工商登記費用是多少？', content: '<p>\n' +
        '                你是正準備要創業開公司嗎？你瞭解該如何申辦工商登記？你知道工商登記的流程嗎？登記費用又是多少？這些問題對第一次申請公司登記或商業登記的人來說，恐怕不太容易。以下將工商登記相關資訊一次整理給您，就算是新手也能輕鬆上手喔！\n' +
        '                <br><br>\n' +
        '                申辦工商登記<br>\n' +
        '                申請方式：<br><br>\n' +
        '                (1)\t網路申請\n' +
        '                <br>\n' +
        '                為提供更方便、快捷的服務，政府於2011年推出「公司與商業及有線合夥一站式線上申請作業」網站，創業民眾可於該網站申辦公司/商業登記預查及設立登記、勞健保設立、工作規則核備等業務。\n' +
        '                <br>\n' +
        '                成立公司之後還需完成記帳、報稅、投保、成立勞保勞退等等的後續事項，如果您是屬於「自行申辦」工商登記和有限合夥的申請人就可以到經濟部的一站式線上申請作業，輕鬆就能辦理完成並節省時間。若您還有使用上的問題、申辦程序與法規疑問，可洽經濟部商工行政客戶服務專線：412-1166。\n' +
        '                <br><br>\n' +
        '                (2)\t現場臨櫃(委託)或郵寄可向誰申請？工商登記受理機關資訊\n' +
        '                <br><br>\n' +
        '                ■\t商業登記<br>\n' +
        '                各縣市政府經濟發展相關局處。\n' +
        '                <br>\n' +
        '                可參考：各縣市政府工商登記單位\n' +
        '                <br><br>\n' +
        '              </p>\n' +
        '              ■\t公司登記\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>經濟部商業司：外國公司、大陸公司、實收資本額新臺幣五億元以上之本國公司。</li>\n' +
        '                <li>經濟部中部辦公室：實收資本額未達新臺幣5億元其所在地在臺灣省及福建省轄區內之本國公司</li>\n' +
        '                <li>經濟部加工出口區管理處：加工出口區內之公司</li>\n' +
        '                <li>臺北市政府、新北市政府、桃園市政府、臺中市政府、臺南市政府、高雄市政府：實收資本額未達新臺幣五億元其所在地在該轄區內之本國公司</li>\n' +
        '                <li>科技部科學園區管理局：科技部科學園區內公司 （含新竹科學園區、中部科學園區、南部科學園區）</li>\n' +
        '                <li>屏東農業生物技術園區籌備處：屏東農業生物技術園區內之公司</li>\n' +
        '                <li>交通部航港局：海港自由貿易港區內之公司(基隆港、臺北港、蘇澳港、臺中港、安平港、高雄港)</li>\n' +
        '                <li>交通部民用航空局：桃園航空自由貿易港區內之公司</li>\n' +
        '              </ul><br>\n' +
        '              <p>\n' +
        '                所需之文件：\n' +
        '                <br>\n' +
        '              </p>\n' +
        '              商業登記應備：\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>申請書（委託他人辦理者，應附具委託書）</li>\n' +
        '                <li>負責人之身分證明文件(屬於合夥組織者，並應檢具合夥人之身分證明文件及合夥契約書) </li>\n' +
        '                <li>資本額證明文件</li>\n' +
        '                <li>所在地之建物所有權狀影本(所有權人非商業負責人或合夥人者，應附具所有權人同意書正本。建物所有權狀影本得以最近一期房屋稅單影本代之；所有權人同意書得以商業與所有權人簽訂之租賃契約影本代之)。</li>\n' +
        '              </ul><br>\n' +
        '              公司登記應備：\n' +
        '              <br><br>\n' +
        '              ■\t有限公司\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>公司設立登記申請書（記得要填預查編號；委託會計師或律師代理者，應另檢附委託書一份）</li>\n' +
        '                <li>其他機關核准函(僑外人投資事業應附投資審議委員投資核准函、資金審定函；經營許可業務者應檢附目的事業主管機關許可文件影本；無則免送)</li>\n' +
        '                <li>公司章程</li>\n' +
        '                <li>股東同意書正本（股東需親自簽名）</li>\n' +
        '                <li>董事願任同意書正本(如擔任董事者已於股東同意書親自簽名同意時，免附)</li>\n' +
        '                <li>股東資格身分證明文件(如有涉及外國文件者，應另檢附中譯本)</li>\n' +
        '                <li>董事資格身分證明文件(含指派代表人之指派書)</li>\n' +
        '                <li>會計師資本額查核報告書暨其附件</li>\n' +
        '                <li>委託會計師簽證之委託書</li>\n' +
        '                <li>設立登記表二份</li>\n' +
        '                <li>建物所有權人同意書正本（應載明同意提供使用之公司名稱）及最近一期房屋完稅稅單（或所有權狀）影本。（建物為公司所有者或檢附租賃契約影本，免附同意書，仍應附最近一期房屋完稅稅單影本或所有權狀影本）</li>\n' +
        '              </ul><br>\n' +
        '              ■\t股份有限公司\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>公司設立登記申請書（記得要填預查編號；委託會計師或律師代理者，應另檢附委託書一份）</li>\n' +
        '                <li>其他機關核准函(僑外人投資事業應附投資審議委員投資核准函、資金審定函；經營許可業務者應檢附目的事業主管機關許可文件影本；無則免送)</li>\n' +
        '                <li>公司章程影本</li>\n' +
        '                <li>發起人會議事錄影本</li>\n' +
        '                <li>董事會議事錄（或董事同意書）影本</li>\n' +
        '                <li>董事、監察人及董事長願任同意書（政府股東指派之董事、監察人免附）影本</li>\n' +
        '                <li>發起人名冊影本</li>\n' +
        '                <li>會計師資本額查核報告書</li>\n' +
        '                <li>發起人資格及身分證明文件影本</li>\n' +
        '                <li>董監事資格及身分證明文件影本</li>\n' +
        '                <li>設立登記表二份</li>\n' +
        '                <li>建物所有權人同意書正本（建物為公司所有者或檢附租賃契約影本，免附同意書）及最近一期房屋稅單</li>\n' +
        '              </ul><br>\n' +
        '              ■\t閉鎖性股份有限公司\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>公司設立登記申請書（記得要填預查編號；委託會計師或律師代理者，應另檢附委託書一份）</li>\n' +
        '                <li>其他機關核准函(僑外人投資事業應附投資審議委員投資核准函、資金審定函；經營許可業務者應檢附目的事業主管機關許可文件影本；無則免送)</li>\n' +
        '                <li>公司章程影本</li>\n' +
        '                <li>發起人會議事錄影本</li>\n' +
        '                <li>董事會議事錄（或董事同意書）影本</li>\n' +
        '                <li>發起人名冊影本及發起人身分證明文件影本</li>\n' +
        '                <li>全體股東同意書影本(閉鎖性股份有限公司股東人數不得超過五十人)</li>\n' +
        '                <li>董監事或其他負責人身分證明文件影本</li>\n' +
        '                <li>董監事願任同意書影本（政府股東指派之董事、監察人免附）</li>\n' +
        '                <li>會計師資本額查核報告書</li>\n' +
        '                <li>設立登記表二份</li>\n' +
        '                <li>建物所有權人同意書正本（建物為公司所有者或檢附租賃契約影本，免附同意書）及最近一期房屋稅單</li>\n' +
        '              </ul><br>\n' +
        '              工商登記的詳細流程：\n' +
        '              <br><br>\n' +
        '              公司及商業登記有分線上與臨櫃申請，線上申辦可以至公司與商業及有限合夥一站式線上申請平台，進行工商登記的流程大致如下：\n' +
        '              <br>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>決定組織設立的型態：公司、行號哪個適合你？</li>\n' +
        '                <li>確定誰當負責人？</li>\n' +
        '                <li>決定營業項目：依據你的產品服務及商業模式，至公司行號及有限合夥營業項目代碼表檢索系統找要經營的項目，也可以參考同業的營業項目，以最主要的營業項目即可，建議15個以內，可加選ZZ99999，除了特許行業外皆可營運。\n' +
        '                    <br>備註：請注意若選擇特許行業的營業項目，需得先向主管機關取得許可證方可申請。</li>\n' +
        '                <li>查詢營業場所是否符合都市計畫及建築管理法規<br>在進行工商登記前，應先向主管機關查詢是否符合都計、建管、消防、衛生等相關法令規定。</li>\n' +
        '                相關主管機關：\n' +
        '                ● 內政部營建署、營建法令<br>\n' +
        '                ● 內政部消防署、內政部消防署法令查詢系統、消防法<br>\n' +
        '                ● 行政院衛生福利部、衛生福利法規檢索系統、食品資訊系統入口網、食品業者登錄平台<br>\n' +
        '                ● 各縣市政府之都市發展局、建築管理工程處、消防局等等<br>\n' +
        '                以臺北市為例，從擇定營業場所、簽訂房屋租賃契約、裝修或辦理登記前各個階段，都可上營業場所預先查詢系統查詢。</li>\n' +
        '                <li>名稱預查：可以先想好1-5個名稱，並透過預查輔助系統（公司名稱暨所營事業預查輔助查詢、商業預查輔助查詢），先確認事業名稱沒有與他人重複，再申請正式的名稱預查。\n' +
        '                <li>刻公司章(大章)、負責人章(小章)及到銀行開戶：由負責人親至銀行開設籌備戶，並存入資本額(行號資本額未達25萬可不用開戶證明)。\n' +
        '                銀行籌備戶申請文件：預查電子核定書、負責人雙證件、負責人章。</li>\n' +
        '                <li>資本額查核：必須到會計師事務所，找一位會計師作資本額查核簽證，並提供簽證報告，費用可能因資本額高低及風險大小有所不同，建議諮詢會計師事務所了解 (行號不須進行)。</li>\n' +
        '                <li>設立登記申請：備妥申請公司/商業登記、稅籍登記等相關文件，送件至縣市政府及國稅局分別申請工商登記與稅籍登記。完成後才可以開始營運喔！</li>\n' +
        '                ● 稅籍登記應備文件：<br>\n' +
        '                (1) 營業人設立事項表申請書<br>\n' +
        '                (2) 負責人身份証、戶籍謄本及其他有效證明文件影本1份<br>\n' +
        '                (3) 合夥組織加附合夥契約副本(影本)1份<br>\n' +
        '                (4) 公司組織及其他組織，主管機關核准成立之證照、組織章程等影本各1份<br>\n' +
        '                (5) 總機構所屬對外營業之其他固定營業場所申請登記，應檢附總機構營業登記核准函影本1份。\n' +
        '                <li>成立勞健保投保單位：依照勞工保險條例規定，公司只有負責人一人，是不能成立勞保單位的，公司未滿5人不強制成立投保單位，公司達5人以上就要強制成立投保單位。</li>\n' +
        '                ●\t申請勞保單位應備文件：<br>\n' +
        '                (1)\t投保申請書<br>\n' +
        '                (2)\t加保申請書<br>\n' +
        '                (3)\t工商登記之證明文件<br>\n' +
        '                (4)\t負責人身分證件正反兩面影本   \n' +
        '                <li>加入各地區同業公會</li>\n' +
        '                瞭解更多：工商登記作業流程及收費表              \n' +
        '              </ul>\n' +
        '              <br>\n' +
        '              工商登記相關費用<br>\n' +
        '              <ul style="list-style-type: decimal;">\n' +
        '                <li>公司登記及商業登記名稱預查，應繳納預查審查費300元。但以網路傳輸方式申請者，應繳納預查審查費150元。</li>\n' +
        '                <li>公司設立登記 實收資本總額每4000元以1元計算；未達1000元者，以1000元計收。以網路方式申請者，應繳納之登記費減收300元。</li>\n' +
        '                <li>商業設立登記1000元，但以網路傳輸方式申請者，應繳納之登記費減徵二成。</li>\n' +
        '                <li>若因門牌改改編、行政區調整或法條變更等因素而必須申請停業、復業、延展開業、解散、登記、廢止許可登記的話，得以免繳納規費。</li>\n' +
        '              </ul>'},
    { id: 49, title: '有限公司、股份有限公司、閉鎖性公司有何差異？3大優缺點一次看', content: '<p>\n' +
        '                到底有限公司、股份有限公司、閉鎖性公司有什麼差異呢？公司的種類、型態這麼多種，究竟要選擇哪一種型態？新創圓夢網也專業為您分享3種公司型態的優缺點，讓你更瞭解哪一種公司類型，最適合你做創業喔！以下將帶您一次瞭解有限公司、股份有限公司和閉鎖性股份有限公司之間的差別，並教您如何選擇！\n' +
        '                <br><br>\n' +
        '                有限公司、股份有限公司、閉鎖性公司差異：\n' +
        '                <br><br>\n' +
        '                ● 有限公司：由一人以上股東所組織，就其出資額為限，對公司負其責任之公司。(公司法第二條)<br>\n' +
        '                ● 股份有限公司：指二人以上股東或政府、法人股東一人所組織，全部資本分為股份；股東就其所認股份，對公司負其責任之公司。(公司法第二條) <br>\n' +
        '                ● 閉鎖性股份有限公司：指股東人數不超過五十人，並於章程定有股份轉讓限制之非公開發行股票公司。前項股東人數，中央主管機關得視社會經濟情況及實際需要增加之；其計算方式及認定範圍，由中央主管機關定之。(公司法第五章第十三節)\n' +
        '              </p>'},
    { id: 50, title: '如何設立閉鎖性股份有限公司呢？', content: '<p>\n' +
        '                閉鎖性股份有限公司之設立程序與一般股份有限公司是相同的，列式主要程序如下：<br>\n' +
        '                (1) 規劃及設計閉鎖性股份有限公司之章程限定事項 (如：股權轉讓、非現金出資、複數表決權特別股…等)<br>\n' +
        '                (2) 向經濟部商業司申請新公司名稱及所營事業預查<br>\n' +
        '                (3) 召開發起人會議及董事會議<br>\n' +
        '                (4) 洽請會計師辦理驗資及出具資本額查核報告書<br>\n' +
        '                (5) 向公司登記主管機關申請公司設立登記<br>\n' +
        '                (6) 閉鎖性股份有限公司應填列股東人數、非以現金出資者應填列章程載明之核給股數與抵充金額<br>\n' +
        '                (信用、勞務出資僅適用閉鎖性股份有限公司)<br>\n' +
        '                (7) 向公司所在地國稅局申請營業人設立登記<br>\n' +
        '                (8) 如有經營進出口業務，向國貿局申請英文名稱預查及申請進出口廠商資格登記\n' +
        '              </p>'},
    { id: 51, title: '外國公司在臺分公司符合臺灣中小企業認定標準', content: '<p>\n' +
        '                外國公司如果來臺灣設立分公司，以營利為目的，依照外國法組織登記之公司，向我國商業司辦理認許，並不符合中小企業所指「依法辦理公司登記或商業登記」；除非是以營利為目的，依我國法律組織登記之子公司。因此外國公司在臺分公司無法申請我國中小企業相關政策貸款。\n' +
        '              </p>'},
    { id: 52, title: '如果沒有登錄資料會有甚麼影響嗎？', content: '<p>\n' +
        '                若借款人未辦理登錄，則承貸金融機構自當年度6月1日起，停止申請利息補貼，唯當年度5月底以前未能申請的利息補貼，仍得依規定請領。\n' +
        '              </p>'},
  ]
}