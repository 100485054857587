
  import { Vue } from 'vue-class-component';
  import { Watch } from 'vue-property-decorator'
  import { useRouter, useRoute } from 'vue-router'
  import { RestService } from "../service/rest.service";

  export default class NewsDetailView extends Vue {

    router = useRouter()
    route = useRoute()

    restService = new RestService('news')

    id: string = this.route.params.id as string
    item: any = null
    others: any = []


    mounted() {
      this.fetchData(this.id)

      this.$nextTick(() => {
        setTimeout(() => {
          let mainElem = document.getElementsByClassName('sec-main');
          let aElems = mainElem[0].getElementsByTagName('a');
          for(let i=0; aElems.length > i; i++) {
            let title = aElems[i].getAttribute('title');
            if (title == null) {
              aElems[i].setAttribute('title', aElems[i].text);
            }
          }

          // 議直接於超連結上加註「另開新視窗」。
          let htmlElem = document.getElementsByClassName('editor-html');
          for(let i=0; htmlElem.length > i; i++) {
            let aHtmlElem = htmlElem[i].getElementsByTagName('a');
            for(let i=0; aHtmlElem.length > i; i++) {
              let title = aHtmlElem[i].getAttribute('title');
              aHtmlElem[i].setAttribute('title', '另開新視窗-' + title);
            }
          }
        }, 1000);

      });

    }

    @Watch('route.params.id')
    fetchData(id: string) {
      this.restService.get(id)
          .then((data) => this.item = data)
          .catch(() => this.router.push('/404'))

      this.restService.getsBy('/' + id + '/other')
          .then((data) => this.others = data)
          .catch((e) => console.log(e))
    }

  }
