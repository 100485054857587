import { createApp } from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import App from './App.vue'
import router from './router'
import store from './store'
import { setLocale } from 'yup';
import {ProgressFinisher, useProgress, Vue3ProgressPlugin} from '@marcoschulte/vue3-progress';
import axios from "axios";
import {createI18n} from "vue-i18n";

const messages = {
  'zh-TW': {
    true: '是',
    false: '否',
    location: {
      HSH: '新竹縣',
      ALL: '全國性'
    },
    fundType: {
      CREDIT: '融資貸款',
      SUBSIDY: '補助補貼',
      FUND: '創投基金',
      OTHER: '其他'
    },
    appointment: {
      GENERAL: '一般通識',
      FUND: '創業資金',
      REGISTRATION: '工商登記',
      OTHER: '其他'
    },
    amount: {
      L1: '100萬（含）以下',
      L2: '100-300萬',
      L3: '300-1000萬',
      L4: '超過1000萬'
    }
  }
}

const i18n = createI18n({
  locale: 'zh-TW',
  fallbackLocale: 'zh-TW',
  messages
});

setLocale({
  array: {
    min: '至少填寫${min}項'
  },
  mixed: {
    default: '必填',
    required: '必須填寫'
  },
  string: {
    email: '請輸入有效的電子郵件',
    url: '請輸入有效的網址',
    min: '最少${min}個字',
    max: '最多${max}個字'
  }
})

const app = createApp(App);

app.directive("body-class", (el: any, binding: any) => {
  if(Array.isArray(binding.value)) {
    document.body.classList.add(...binding.value)
  } else {
    document.body.classList.add(binding.value)
  }
})

app.directive("title", (el: any, binding: any) => {
  document.title = binding.value + ' | HYS新創竹夢資源網'
})

app.use(VueReCaptcha, {
  siteKey: '6LfEV1YnAAAAAIxqCq5JKMtU-L_nHOca8PPylp8f',
  loaderOptions: {
    useRecaptchaNet: true
  }
})

app.use(i18n).use(store).use(router).use(Vue3ProgressPlugin).mount('#app')

const progresses = [] as ProgressFinisher[];

axios.interceptors.request.use(config => {
  progresses.push(useProgress().start());
  return config;
});

axios.interceptors.response.use(resp => {
  progresses.pop()?.finish();
  return resp;
}, (error) => {
  progresses.pop()?.finish();
  return Promise.reject(error);
});