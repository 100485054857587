<template>
  <main id="main" v-title="'關於我們'">
    <section class="about-hero bg-primary">
      <div class="container-lg">
        <div class="col-md-10 offset-md-1 text-center text-md-start">
          <img class="img-fluid" src="resources/images/logo/logo-hys_white@3x.png" width="468" alt="">
        </div>
      </div>
    </section>
    <section class="sec-main about-main bg-gray">
      <div class="container-lg">
        <div class="col-md-10 mx-auto">
          <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><router-link to="/" title="首頁" class="foo: false">首頁</router-link></li>
              <li class="breadcrumb-item active" aria-current="page">關於我們 - 認識平台</li>
            </ol>
          </nav>
        </div>
        <div class="row">
          <div class="col-md-10 mx-auto">
            <h1 class="visually-hidden">認識平台</h1>
            <div class="mb-md-5 mb-4">
              <h2 class="h2 text-primary mb-3">平台背景</h2>
              <p class="h4">新竹縣是一座年輕有朝氣的城市，本縣15至45歲青年人口比例佔總人口的4成以上，鄰近有7所大專院校、國際級產業重鎮新竹科學園區、新竹生物醫學園區、AI智慧園區，以及國家級的研發中心工業技術研究院等，擁有人才培育、技術研發及產業輔導等豐富資源。
                新竹縣政府積極推動青年創新創業，從地方創生推動、新竹青創基地成立營運、新竹AIoT加速器平台建置、大學生創業實戰訓練營辦理等，透過各種創新培育的方式，提供不同階段青年創業所需的資源，希望能藉此吸引更多具備創新思考青年，帶動新竹產業發展。
                而近年中央部會如行政院科技會報辦公室、國家發展委員會、經濟部、科技部等，均持續擴大推動創新創業計畫。而新創築夢網不僅彙整了中央、新竹縣新創資源，更提供民眾諮詢服務，以培育青年就業、創新、創業，催生新型態的青年創新創業聚落。</p>
            </div>
            <div class="mb-md-5 mb-4">
              <h2 class="h2 text-primary mb-3">平台目的</h2>
              <p class="h4">HYS新創竹夢資源網以青年創新創業為重點，彙整新竹縣政府各局處相關創新創業等資源，同時盤整出中央政府相關計畫獎補助、投資等資源，供新創者能更快速有效的找到所需資訊及資源。</p>
            </div>
            <div class="mb-md-5 mb-4">
              <h2 class="h2 text-primary mb-3">平台服務</h2>
              <p class="h4">HYS新創竹夢資源網是新竹縣為整合中央及新竹縣創新創業資源，而打造的一個資源整合平台。新創者除可了解各種創業資源、重要創業活動、輔導諮詢外，更能透過創業診斷分析了解自身創業適性取向。</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 col-lg-11 offset-lg-1 me-lg-0 mx-auto">
            <div class="">
              <h2 class="h2 text-primary mb-4">聯絡我們</h2>
              <div class="fcss flex-lg-row">
                <div class="about-info flex-grow-1">
                  <div class="mb-3 mb-md-4">
                    <div class="h3 mb-md-1">服務時間</div>
                    <div class="h4">週一到週五 9:00-17:00</div>
                  </div>
                  <div class="mb-3 mb-md-4">
                    <div class="h3 mb-md-1">地址</div>
                    <div class="h4">新竹縣竹北市光明六路10號</div>
                  </div>
                  <div class="mb-3 mb-md-4">
                    <div class="h3 mb-md-1">聯絡資訊</div>
                    <div class="h4">聯絡窗口：新竹縣政府教育局青年事務科</div>
                    <div class="h4">連絡電話：03-5518101#2824、2895、2893、2834、2864</div>
                  </div>
                </div>
                <div class="about-map mt-4 mt-md-0">
                  <div class="map-placeholder">
                    <iframe title="開啟 Google Map 地圖網頁(另開視窗)" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.0625469999873!2d121.01077607601762!3d24.82753454657312!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346836ed00327631%3A0xab011daa0903e57c!2zMzAy5paw56u557ij56u55YyX5biC5YWJ5piO5YWt6LevMTDomZ8!5e0!3m2!1szh-TW!2stw!4v1686800915788!5m2!1szh-TW!2stw" width="612" height="356" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script setup lang="ts">
</script>