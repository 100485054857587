
  import { Options, Vue } from 'vue-class-component';
  import { QA } from "./qa/qa";

  export default class QAView extends Vue {

    data: any = QA.getDatas()
    tab = 1

    changeTab(n: number) {
      this.tab = n
    }

    type1_lists = [
      QA.getData(1),
      QA.getData(2),
      QA.getData(3),
      QA.getData(4),
      QA.getData(5),
      QA.getData(6),
      QA.getData(7),
      QA.getData(8),
      QA.getData(9)
    ]
    type2_lists = [
      QA.getData(10),
      QA.getData(11),
      QA.getData(12),
      QA.getData(13),
      QA.getData(14),
      QA.getData(15),
      QA.getData(16),
      QA.getData(17),
      QA.getData(18),
      QA.getData(19),
      QA.getData(20),
      QA.getData(21),
      QA.getData(22),
      QA.getData(23),
      QA.getData(24),
      QA.getData(25),
      QA.getData(26),
      QA.getData(27),
      QA.getData(28),
      QA.getData(29),
      QA.getData(30),
      QA.getData(31),
      QA.getData(32),
      QA.getData(33),
      QA.getData(34),
      QA.getData(35),
      QA.getData(52)
    ]
    type3_lists = [
      QA.getData(36),
      QA.getData(37),
      QA.getData(38),
      QA.getData(39),
      QA.getData(40),
      QA.getData(41),
      QA.getData(42),
      QA.getData(43),
      QA.getData(44),
      QA.getData(45),
      QA.getData(46),
      QA.getData(47),
      QA.getData(48),
      QA.getData(49),
      QA.getData(50),
      QA.getData(51)
    ]
  }
