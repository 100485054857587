
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop, Ref, Watch } from 'vue-property-decorator'

export default class PaginationComponent extends Vue {

  @Prop()
  totalPages!: number
  currentPage = 1
  range: number[] = []

  @Watch('totalPages')
  pageRange() {
    const page = this.currentPage
    const total = this.totalPages

    const baseNumber = 5
    let size = 10
    let length = total > size ? size : total
    let n = page - baseNumber
    if (n < 1) {
      n = 1
    } else if (n + length > total) {
      n = total - length + 1
    }

    this.range = Array.from({ length: length }, (_, index) => index + n)
  }

  prev() {
    let page = this.currentPage - 1
    if (page < 1) page = 1
    this.onClick(page)
  }

  next() {
    let page = this.currentPage + 1
    if (page > this.totalPages) page = this.totalPages
    this.onClick(page)
  }

  @Emit('onClick')
  onClick(page: number): void {
    this.currentPage = page

    if (this.range.length <= 1) {
      return;
    }

    const min = this.range[1]
    const max = this.range[this.range.length - 2]

    if (page <= min || page >= max) {
      this.pageRange()
    }
  }
}
