
  import { Options, Vue } from 'vue-class-component';
  import { nextTick } from 'vue';
  import axios from "axios";
  import {RestService} from "../service/rest.service";
  import PaginationComponent from '@/components/pagination/PaginationComponent.vue';

  @Options({
    components: {
      PaginationComponent
    }
  })
  export default class ResourceView extends Vue {

    restService = new RestService('resources')
    items: any = []
    totalPages = 0

    searchForm: any = {
      location: '',
      type: '',
      stage: '',
      page: 1
    }

    showSelect = {
      location: false,
      type: false,
      stage: false
    }

    locations = {
      '': '全部地區',
      ALL: '全國性',
      HSH: '新竹縣'
    }

    types = {
      '': '不限',
      T1: '創業資金',
      T2: '業師輔導',
      T3: '活動課程',
      T4: '行銷通路',
      T5: '競賽獎項',
      T6: '國際鏈接',
      T9: '其他'
    }

    stages = {
      '': '不限',
      S1: '創意萌芽',
      S2: '新創啟動',
      S3: '創業成長',
      S9: '其他'
    }

    mounted() {
      this.gets(this.searchForm)
    }

    gets(queryString?: any) {
      this.searchForm = queryString
      this.restService.gets(queryString).then((data: any) => {
        this.totalPages = data.totalPages
        this.items = data.content
      })
    }

    changePage(page: number) {
      this.searchForm.page = page
      this.gets(this.searchForm)
    }
  }
