
import { Vue } from 'vue-class-component';
import { nextTick } from 'vue';
import $ from 'jquery';
import 'slick-carousel';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { RestService } from "../service/rest.service";

export default class HomeView extends Vue {

  bannersService = new RestService('banners')
  eventsService = new RestService('events')

  banners: any = []
  news: any = []

  mounted() {

    this.bannersService.gets().then((data: any) => {
      this.banners = data

      nextTick(() => {

        $("#heroSlider").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          autoplay: false,
          dots: true,
          arrows: true
        })
      });
    })

    this.eventsService.gets({sort: 4}).then((data: any) => {
      this.news = data

      nextTick(() => {
        gsap.registerPlugin(ScrollTrigger);

        let infoArticles = $('.index-info article')
        let starupCards = $('.startup-group')
        let starupHead = $('.index-startup .head-row img')
        let eventCards = $('.event-group')
        let serviceArticles = $('.index-service article')
        let articleArr = [...serviceArticles];
        let tl = gsap.timeline();
        let mm = gsap.matchMedia();

        mm.add("(min-width: 800px)", () => {
          gsap.from(infoArticles, {
            scrollTrigger: { trigger: '.index-info', scrub: 1, once: true, start: 'top center', end: "70% 50%"},
            y: -100, autoAlpha: 0, ease: "ease-in", duration: .25
          })
        })
        gsap.from(starupHead, {
          scrollTrigger: { trigger: '.index-startup', scrub: 1, once: true, start: 'top center', end: "70% 50%"},
          y: -100, autoAlpha: 0, ease: "ease-in", duration: .25
        })
        gsap.from(starupCards, {
          scrollTrigger: { trigger: '.index-startup', scrub: 1, once: true, start: 'top center', end: "70% 50%"},
          y: -100, autoAlpha: 0, ease: "ease-in", duration: .25
        })
        gsap.from(eventCards, {
          scrollTrigger: { trigger: '.index-event', scrub: 1, once: true, start: 'top center', end: "70% 50%"},
          y: -100, autoAlpha: 0, ease: "ease-in", duration: .25
        })
        articleArr.forEach(function (element) {
          tl.from(element, {
            y: -100, autoAlpha: 0, ease: "ease-in", duration: .25
          })
        })
        // set trigger
        ScrollTrigger.create({
          trigger: $('.index-service'),
          start: "0% 50%",
          end: "70% 50%",
          scrub: 1,
          once: true,
          toggleActions: "play none none none",
          animation: tl
        })
      });
    })
  }
}
