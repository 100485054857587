
  import { Options, Vue } from 'vue-class-component';
  import {RestService} from "../service/rest.service";
  import PaginationComponent from '@/components/pagination/PaginationComponent.vue';
  import { ProvideReactive } from "vue-property-decorator";
  import { reactive } from "vue"

  @Options({
    components: {
      PaginationComponent
    }
  })
  export default class NewsView extends Vue {

    bodyClasses = reactive({
      darkMode: false,
      otherClass: true,
    })

    test() {
      this.bodyClasses.darkMode = !this.bodyClasses.darkMode
      console.log(this.bodyClasses.darkMode)
    }

    @ProvideReactive('darkMode')
    darkMode = false
    restService = new RestService('news')
    searchForm: any = {}
    items: any = []
    totalPages = 0

    mounted() {
      this.gets()
    }

    gets(queryString?: any) {
      this.restService.gets(queryString).then((data: any) => {
        this.totalPages = data.totalPages
        this.items = data
      })
    }

    changePage(page: number) {
      this.searchForm.page = page
      this.gets(this.searchForm)
    }
  }
