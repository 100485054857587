

  import { Vue } from 'vue-class-component';
  import {RestService} from "../service/rest.service";

  export default class AIoTAcceleratorView extends Vue {

    restService = new RestService('events')
    tab = 1
    page: any = {}

    changeTab(n: number) {
      this.tab = n
    }

    mounted() {
      this.gets({aiOt: true, size: 3})
    }

    gets(queryString?: any) {
      this.restService.gets(queryString).then((data: any) => {
        this.page = data
      })
    }
  }
