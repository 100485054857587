
  import { Vue } from 'vue-class-component';
  import { Watch } from 'vue-property-decorator'
  import { useRouter, useRoute } from 'vue-router'
  import { RestService } from "../service/rest.service";

  export default class EventDetailView extends Vue {

    router = useRouter()
    route = useRoute()

    restService = new RestService('events')

    id: string = this.route.params.id as string
    item: any = null
    others: any = []


    mounted() {
      this.fetchData(this.id)
    }

    @Watch('route.params.id')
    fetchData(id: string) {
      this.restService.get(id)
          .then((data) => this.item = data)
          .catch(() => this.router.push('/404'))

      this.restService.getsBy('/' + id + '/other')
          .then((data) => this.others = data)
          .catch((e) => console.log(e))
    }

  }
