
import { Vue } from 'vue-class-component';
import {useRoute, useRouter} from "vue-router";
export default class Error404View extends Vue {

  router = useRouter()
  route = useRoute()

  mounted() {
    alert('此頁面不存在');
    this.router.push('/')
  }

}
